import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { RootState } from '../../../store/RootReducer';
import Preloader from '../../Components/Preloader';
import { actions } from '../slice';
import TimeLine from '../../Components/TimeLine';
import VideoPopUp from '../../Components/VideoPopUp';
import newMain from '../../../img/page/newMain-bg2.png'
import NextBlock from '../../Components/NextBlock';
import style from '../B2Camp/style.module.css';

const Trebono = () => {
  const { trebonoData, isFetching } = useSelector((state: RootState) => state.pages);
  const [isActive, setIsActive] = useState('slider1');
  const [isOpen, setOpen] = useState<{ value: boolean, link: any }>({ value: false, link: null });
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (trebonoData === null) {
      dispatch(actions.loadData({ fetch: true, endPoint: pathname }));
    }
  }, [trebonoData]);
  return <>
    {isFetching && <Preloader />}
    {!isFetching && <>
      <main className='page-main'>
        <div className='container'>
          <div className='page-main__inner'>
            <div className='page-main__content'>
              <div className='page-main__title text'
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{ __html: trebonoData?.mainBlock?.title.value || '' }} />
              <div className='page-main__descr'
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{ __html: trebonoData?.mainBlock?.description.value || '' }} />
            </div>
          </div>
        </div>
        <span className='page-main__bg'><img src={newMain} alt=''
                                             className='page-main__bg-item' /></span>
      </main>
      <div className='page-info'>
        <div className='container'>
          <div className='page-info__title text'
            // eslint-disable-next-line react/no-danger
               dangerouslySetInnerHTML={{ __html: trebonoData?.infoBlock?.infoAboutApp?.title.value || '' }} />
          <div className='page-info__inner'>
            <div className='page-info__block'>
              {trebonoData?.infoBlock?.infoBlockWithLogo?.map(item =>
                <div className='page-info__box' key={item.id}>
                  <div className='page-info__box-img'><img src={item.logo.value} alt=''
                                                           className='page-info__box-img-item' /></div>
                  <div className='page-info__box-text'
                    // eslint-disable-next-line react/no-danger
                       dangerouslySetInnerHTML={{ __html: item?.description.value || '' }} />
                </div>)}
            </div>
            <div className='page-info__content'>
              <div className='page-info__text'
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={
                     { __html: trebonoData?.infoBlock?.infoAboutApp?.description.value || '' }} />
              <div className='page-info__download'>
                <div className='page-info__download-title text'
                  // eslint-disable-next-line react/no-danger
                     dangerouslySetInnerHTML={
                       { __html: trebonoData?.infoBlock?.infoAboutDownloadApp?.title.value || '' }
                     } />
                <div className='page-info__download-links'>
                  {trebonoData?.infoBlock?.infoAboutDownloadApp?.logo.map(item =>
                    <a href={item.link.value} target='_blank' rel='noopener noreferrer'
                       className='page-info__download-item' key={item.id}>
                      <img src={item.logo.value} alt=''
                           className='page-info__download-img' /></a>)}
                </div>
              </div>
            </div>

            <div className='slider'>
              <div className='slider__inner'>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                {trebonoData?.infoBlock?.slider?.map(item => <div
                  className={`slider__item slider__item${item.id}
                            ${isActive === `slider${item.id}` ? 'active' : ''}`}
                  onClick={() => setIsActive(`slider${item.id}`)} key={item.id}>
                  <img src={item.logoSlider.value} alt=''
                       className='slider__item-img slider__item-img1' /><span
                  className='slider__item-play'>
                  {/* eslint-disable-next-line max-len */}
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                                <img src={item.logoPlay.value} alt=''
                                     className='slider__item-play-img' onClick={() => setOpen({
                                  value: true, link: item.link.value})} /></span>
                </div>)}
              </div>
            </div>

            <div className='history'>
              <div className='history__title title text'
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={
                     { __html: trebonoData?.infoBlock?.historyBlock?.title.value || '' }} />
              <div className='history__inner'>
                <div className='history__text'
                  // eslint-disable-next-line react/no-danger
                     dangerouslySetInnerHTML={
                       { __html: trebonoData?.infoBlock?.historyBlock?.description.value || '' }} />
                <div className='history__info'
                  // eslint-disable-next-line react/no-danger
                     dangerouslySetInnerHTML={
                       { __html: trebonoData?.infoBlock?.historyBlock?.info.value || '' }} />

              </div>
            </div>

            <div className='page-info__testimonials'>
              <div className='page-info__testimonials-inner'>
                <div className='page-info__testimonials-box'>
                  <div className='page-info__testimonials-text'
                    // eslint-disable-next-line react/no-danger
                       dangerouslySetInnerHTML={
                         { __html: trebonoData?.infoBlock?.historyBlock?.report.title.value || '' }} />
                </div>
                <div className='page-info__testimonials-person'>
                  <div className='page-info__testimonials-icon'>
                    <img src={trebonoData?.infoBlock?.historyBlock?.report.logo.value}
                         alt='' className='svg-img' />
                  </div>
                  <div className='page-info__testimonials-person-info'>
                    <div className='testimonials-person-name'
                      // eslint-disable-next-line react/no-danger
                         dangerouslySetInnerHTML={
                           { __html: trebonoData?.infoBlock?.historyBlock?.report.name.value || '' }} />
                    <div className='testimonials-person-work'
                      // eslint-disable-next-line react/no-danger
                         dangerouslySetInnerHTML={{
                           __html: trebonoData?.infoBlock?.historyBlock?.report.description.value
                             || '',
                         }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {trebonoData?.infoBlock?.historyBlock?.report.backgroundLogo?.map(item => <span
          className={`bg bg${item.id}`} key={item.id}>
                <img src={item.logo.value} alt='' className='bg__img' />
            </span>)}

      </div>
      <div className='htd'>
        <div className='htd__content'>
          <div className='container'>
            <div className='htd__inner'>
              <div className='htd__img'>
                <img src={trebonoData?.howItWorkBlock?.logoMobile.value} alt='' className='htd__img-item' />
              </div>
              <div className='htd__title title text'
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{ __html: trebonoData?.howItWorkBlock?.title.value || '' }} />
              <div className='htd__text'
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{ __html: trebonoData?.howItWorkBlock?.description.value || '' }} />
              <div className='htd__testimonials'>
                <div className='htd__testimonials-inner'>
                  <div className='htd__testimonials-box'>
                    <div className='htd__testimonials-text'
                      // eslint-disable-next-line react/no-danger
                         dangerouslySetInnerHTML={
                           { __html: trebonoData?.howItWorkBlock?.report.title.value || '' }} />
                  </div>
                  <div className='htd__testimonials-person'>
                    <div className='htd__testimonials-icon'>
                      <img src={trebonoData?.howItWorkBlock?.report.logo.value} alt='' className='svg-img' />
                    </div>
                    <div className='htd__testimonials-person-info'>
                      <div className='testimonials-person-name'
                        // eslint-disable-next-line react/no-danger
                           dangerouslySetInnerHTML={
                             { __html: trebonoData?.howItWorkBlock?.report.name.value || '' }} />
                      <div className='testimonials-person-work'
                        // eslint-disable-next-line react/no-danger
                           dangerouslySetInnerHTML={{
                             __html: trebonoData?.howItWorkBlock?.report.description.value
                               || '',
                           }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span className='htd-bg'>
            <img src={trebonoData?.howItWorkBlock?.logoBig.value} alt='' className='htd-bg-img' /></span>
      </div>
      <div className='confirm'>
        <div className='container'>
          <div className='confirm__inner'>
            <div className='confirm__content'>
              <div className='confirm__title title text'
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{ __html: trebonoData?.confirmBlock?.title.value || '' }} />
              <div className='confirm__text'
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{ __html: trebonoData?.confirmBlock?.underTitle.value || '' }} />
              <div className='confirm__testimonials'>
                <div className='confirm__testimonials-inner'>
                  <div className='confirm__testimonials-box'>
                    <div className='confirm__testimonials-text'
                      // eslint-disable-next-line react/no-danger
                         dangerouslySetInnerHTML={
                           { __html: trebonoData?.confirmBlock?.reportOne.title.value || '' }} />
                  </div>
                  <div className='confirm__testimonials-person'>
                    <div className='confirm__testimonials-icon'>
                      <img src={trebonoData?.confirmBlock?.reportOne.logo.value} alt='' className='svg-img' />
                    </div>
                    <div className='confirm__testimonials-person-info'>
                      <div className='testimonials-person-name'
                        // eslint-disable-next-line react/no-danger
                           dangerouslySetInnerHTML={
                             { __html: trebonoData?.confirmBlock?.reportOne.name.value || '' }}
                      />
                      <div className='testimonials-person-work'
                        // eslint-disable-next-line react/no-danger
                           dangerouslySetInnerHTML={{
                             __html: trebonoData?.confirmBlock?.reportOne.description.value
                               || '',
                           }} />
                    </div>
                  </div>
                </div>
              </div>
              <p className='confirm__descr'
                // eslint-disable-next-line react/no-danger
                 dangerouslySetInnerHTML={{ __html: trebonoData?.confirmBlock?.description.value || '' }} />
              <div className='confirm__testimonials confirm__testimonials2'>
                <div className='confirm__testimonials-inner'>
                  <div className='confirm__testimonials-box'>
                    <div className='confirm__testimonials-text'
                      // eslint-disable-next-line react/no-danger
                         dangerouslySetInnerHTML={
                           { __html: trebonoData?.confirmBlock?.reportTwo.title.value || '' }} />
                  </div>
                  <div className='confirm__testimonials-person'>
                    <div className='confirm__testimonials-icon'>
                      <img src={trebonoData?.confirmBlock?.reportTwo.logo.value} alt='' className='svg-img' />
                    </div>
                    <div className='confirm__testimonials-person-info'>
                      <div className='testimonials-person-name'
                        // eslint-disable-next-line react/no-danger
                           dangerouslySetInnerHTML={
                             { __html: trebonoData?.confirmBlock?.reportTwo.name.value || '' }}
                      />
                      <div className='testimonials-person-work'
                        // eslint-disable-next-line react/no-danger
                           dangerouslySetInnerHTML={{
                             __html: trebonoData?.confirmBlock?.reportTwo.description.value
                               || '',
                           }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='confirm__testimonials confirm__testimonials3'>
                <div className='confirm__testimonials-inner'>
                  <div className='confirm__testimonials-box'>
                    <div className='confirm__testimonials-text'
                      // eslint-disable-next-line react/no-danger
                         dangerouslySetInnerHTML={
                           { __html: trebonoData?.confirmBlock?.reportThree.title.value || '' }} />
                  </div>
                  <div className='confirm__testimonials-person'>
                    <div className='confirm__testimonials-icon'>
                      <img src={trebonoData?.confirmBlock?.reportThree.logo.value} alt=''
                           className='svg-img' />
                    </div>
                    <div className='confirm__testimonials-person-info'>
                      <div className='testimonials-person-name'
                        // eslint-disable-next-line react/no-danger
                           dangerouslySetInnerHTML={{
                             __html: trebonoData?.confirmBlock?.reportThree.name.value
                               || '',
                           }} />
                      <div className='testimonials-person-work'
                        // eslint-disable-next-line react/no-danger
                           dangerouslySetInnerHTML={{
                             __html: trebonoData?.confirmBlock?.reportThree.description.value
                               || '',
                           }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='confirm__img'>
              <img src={trebonoData?.confirmBlock?.logo.value} alt='' className='confirm__img-item img-item' />
            </div>
          </div>
        </div>
      </div>
      <section className='money'>
        <div className='container'>
          <div className='money__title title text'
            // eslint-disable-next-line react/no-danger
               dangerouslySetInnerHTML={{ __html: trebonoData?.moneyBlock?.title.value || '' }} />
          <div className='money__inner'>
            <div className='content'>
              <div className='testimonials'>
                <div className='testimonials-inner'>
                  <div className='testimonials-box'>
                    <div className='testimonials-text'
                      // eslint-disable-next-line react/no-danger
                         dangerouslySetInnerHTML={
                           { __html: trebonoData?.moneyBlock?.reportOne.title.value || '' }} />
                  </div>
                  <div className='testimonials-person'>
                    <div className='testimonials-icon'>
                      <img src={trebonoData?.moneyBlock?.reportOne.logo.value} alt='' className='svg-img' />
                    </div>
                    <div className='testimonials-person-info'>
                      <div className='testimonials-person-name'
                        // eslint-disable-next-line react/no-danger
                           dangerouslySetInnerHTML={
                             { __html: trebonoData?.moneyBlock?.reportOne.name.value || '' }} />
                      <div className='testimonials-person-work'
                        // eslint-disable-next-line react/no-danger
                           dangerouslySetInnerHTML={{
                             __html: trebonoData?.moneyBlock?.reportOne.description.value
                               || '',
                           }} />
                    </div>
                  </div>
                </div>
              </div>

              <div className='money__text'
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{ __html: trebonoData?.moneyBlock?.description.value || '' }} />

              <div className='testimonials'>
                <div className='testimonials-inner'>
                  <div className='testimonials-box'>
                    <div className='testimonials-text'
                      // eslint-disable-next-line react/no-danger
                         dangerouslySetInnerHTML={
                           { __html: trebonoData?.moneyBlock?.reportTwo.title.value || '' }} />
                  </div>
                  <div className='testimonials-person'>
                    <div className='testimonials-icon'>
                      <img src={trebonoData?.moneyBlock?.reportTwo.logo.value} alt='' className='svg-img' />
                    </div>
                    <div className='testimonials-person-info'>
                      <div className='testimonials-person-name'
                        // eslint-disable-next-line react/no-danger
                           dangerouslySetInnerHTML={
                             { __html: trebonoData?.moneyBlock?.reportTwo.name.value || '' }} />
                      <div className='testimonials-person-work'
                        // eslint-disable-next-line react/no-danger
                           dangerouslySetInnerHTML={{
                             __html: trebonoData?.moneyBlock?.reportTwo.description.value
                               || '',
                           }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='money__feedback'>
              <div className='money__feedback-title'
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{ __html: trebonoData?.moneyBlock?.moneyFeedBack.title.value || '' }} />
              <div className='money__feedback-cost'
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={
                     { __html: trebonoData?.moneyBlock?.moneyFeedBack.description.value || '' }} />
            </div>
          </div>
        </div>
      </section>
      <section className='saving'>
        <div className='container'>
          <div className='saving__inner'>
            <div className='content'>
              <div className='saving__title title text'
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{ __html: trebonoData?.savingMoneyBlock?.title.value || '' }} />
              <div className='saving__text saving__text'
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{ __html: trebonoData?.savingMoneyBlock?.description.value || '' }} />
              {trebonoData?.savingMoneyBlock?.report.map(item => <div key={item.id}
                                                                      className='testimonials saving__testimonials1'>
                <div className='testimonials-inner'>
                  <div className='testimonials-box'>
                    <div className='testimonials-text'
                      // eslint-disable-next-line react/no-danger
                         dangerouslySetInnerHTML={{ __html: item?.title.value }} />
                  </div>
                  <div className='testimonials-person'>
                    <div className='testimonials-icon'><img src={item.logo.value} alt=''
                                                            className='svg-img' />
                    </div>
                    <div className='testimonials-person-info'>
                      <div className='testimonials-person-name'
                        // eslint-disable-next-line react/no-danger
                           dangerouslySetInnerHTML={{ __html: item.name.value || '' }} />
                      <div className='testimonials-person-work'
                        // eslint-disable-next-line react/no-danger
                           dangerouslySetInnerHTML={{ __html: item?.description.value }} />
                    </div>
                  </div>
                </div>
              </div>)}
              <div className='saving__text saving__text2'>
                <p // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: trebonoData?.savingMoneyBlock?.aboutUs.value || '' }} />
              </div>
            </div>
            <div className='saving__img'>
              <img src={trebonoData?.savingMoneyBlock?.image.value} alt='' className='saving__img-item' /></div>
          </div>
        </div>
      </section>
      <section className='manage'>
        <div className='container'>
          <div className='manage__inner'>
            <div className='content'>
              <div className='manage__title title text'
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{ __html: trebonoData?.manageBlock?.title.value || '' }} />
              <div className='manage__text manage__text1'
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{ __html: trebonoData?.manageBlock?.description.value || '' }} />
              <div className='testimonials'>
                <div className='testimonials-inner'>
                  <div className='testimonials-box'>
                    <div className='testimonials-text'
                      // eslint-disable-next-line react/no-danger
                         dangerouslySetInnerHTML={{ __html: trebonoData?.manageBlock?.report.title.value || '' }} />
                  </div>
                  <div className='testimonials-person'>
                    <div className='testimonials-icon'>
                      <img src={trebonoData?.manageBlock?.report.logo.value} alt='' className='svg-img' />
                    </div>
                    <div className='testimonials-person-info'>
                      <div className='testimonials-person-name'
                        // eslint-disable-next-line react/no-danger
                           dangerouslySetInnerHTML={
                             { __html: trebonoData?.manageBlock?.report.name.value || '' }} />
                      <div className='testimonials-person-work'
                        // eslint-disable-next-line react/no-danger
                           dangerouslySetInnerHTML={{
                             __html: trebonoData?.manageBlock?.report.description.value
                               || '',
                           }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='manage__text manage__text2'
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{ __html: trebonoData?.manageBlock?.aboutUs.value || '' }} />
            </div>

            <div className='manage__info'>
              {trebonoData?.manageBlock?.manageInfo.map(item => <div className='manage__info-box' key={item.id}>
                <div className='num'
                  // eslint-disable-next-line react/no-danger
                     dangerouslySetInnerHTML={{ __html: item.title.value || '' }} />
                <div className='descr'
                  // eslint-disable-next-line react/no-danger
                     dangerouslySetInnerHTML={{ __html: item.description.value || '' }} />
              </div>)}
            </div>
          </div>
        </div>
      </section>
      <section className='agile'>
        <div className='container'>
          <div className='agile__inner'>
            <div className='content'>
              <div className='agile__title title text'
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{ __html: trebonoData?.agileBlock?.title.value || '' }} />
              <div className='agile__text agile__text1 text2'
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{ __html: trebonoData?.agileBlock?.description.value || '' }} />
              <div className='testimonials'>
                <div className='testimonials-inner'>
                  <div className='testimonials-box'>
                    <div className='testimonials-text'
                      // eslint-disable-next-line react/no-danger
                         dangerouslySetInnerHTML={{ __html: trebonoData?.agileBlock?.report.title.value || '' }} />
                  </div>
                  <div className='testimonials-person'>
                    <div className='testimonials-icon'>
                      <img src={trebonoData?.agileBlock?.report.logo.value} alt='' className='svg-img' />
                    </div>
                    <div className='testimonials-person-info'>
                      <div className='testimonials-person-name'
                        // eslint-disable-next-line react/no-danger
                           dangerouslySetInnerHTML={
                             { __html: trebonoData?.agileBlock?.report.name.value || '' }} />
                      <div className='testimonials-person-work'
                        // eslint-disable-next-line react/no-danger
                           dangerouslySetInnerHTML={{
                             __html: trebonoData?.agileBlock?.report.description.value
                               || '',
                           }} />
                    </div>
                  </div>
                </div>
                <div className='agile__text agile__text2 text2'>
                  <p // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: trebonoData?.agileBlock?.aboutUs.value || '' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <span className='agile-bg'><img src={trebonoData?.agileBlock?.image.value} alt=''
                                        className='agile-bg-img img-item' /></span>
      </section>
      <section className='components'>
        <div className='container'>
          <div className='components__inner'>
            <div className='components__title title text'
              // eslint-disable-next-line react/no-danger
                 dangerouslySetInnerHTML={{ __html: trebonoData?.componentsBlock?.title.value || '' }} />
            <div className='components__text text2'
              // eslint-disable-next-line react/no-danger
                 dangerouslySetInnerHTML={{ __html: trebonoData?.componentsBlock?.description.value || '' }} />
            <div className='components__img'>
              <img src={trebonoData?.componentsBlock?.image.value} alt=''
                   className='components__img-item img-item' /></div>
            <div className='components__descr text2'
              // eslint-disable-next-line react/no-danger
                 dangerouslySetInnerHTML={{ __html: trebonoData?.componentsBlock?.aboutUs.value || '' }} />
          </div>
        </div>
      </section>
      <TimeLine
        title={trebonoData?.timeLine.title.value}
        items={trebonoData?.timeLine.items}
        dots={trebonoData?.timeLine.imageDots.value}
        image={trebonoData?.timeLine.image.value}
        imageActive={trebonoData?.timeLine.imageActive.value}
      />
      <section className='who'>
        <div className='container'>
          <div className='who__title title text'
            // eslint-disable-next-line react/no-danger
               dangerouslySetInnerHTML={{ __html: trebonoData?.whoIsWhoBox?.title.value || '' }} />
          <div className='who__inner'>
            {trebonoData?.whoIsWhoBox?.item?.map(item => <div className='who__box' key={item.id}>
              <div className='who__box-img'><img src={item.logo.value} alt=''
                                                 className='who__box-img-item' />
              </div>
              <div className='who__box-text'><span>{item.nameCompany.value}</span>{item.description.value}
              </div>
            </div>)}
          </div>
        </div>
      </section>
      <div className={style.containerWhiteBlockWithOutBottom}>
      <NextBlock link={trebonoData?.nextPage.link.value} logo={trebonoData?.nextPage.image.value} id={2}/>
      </div>
    </>}
    <VideoPopUp onClose={()=>setOpen({ value: false, link: null })} open={isOpen.value} link={isOpen.link} />
  </>;
};

export default Trebono;


