import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import { useDispatch, useSelector } from 'react-redux';
import Header from './Components/Header';
import '../css/aos.css';
import '../css/style.css';
import '../css/media.css';
import '../css/bootstrap-grid.min.css';
import '../css/bootstrap-grid.min.min.css';
import RouteSwitcher from './Components/RouterSwitcher';
import FooterForm from './Components/Footer';
import { RootState } from '../store/RootReducer';
import Preloader from './Components/Preloader';
import { actions } from './slice';
import Notification from './Components/Notification';
import PopUp from './Components/PopUp';

const App = () => {
  const { footerData, isFetching, success, error } = useSelector((state: RootState) => state.main);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState<string | string[]>('');
  const [isShowPopUp, setIsShowPopUp] = useState(false);
  const [type, setType] = useState('');
  const dispatch = useDispatch();
  const closeNotify = () => {
    setIsOpen(false);
    dispatch(actions.setError(null));
    dispatch(actions.sendFormSuccess(null));
  };

  useEffect(() => {
    AOS.init({
      easing: 'ease-in-sine',
      startEvent: 'scroll',
    });
    AOS.refresh();
    window.scrollTo(0, 1);
  }, []);

  useEffect(() => {
    if (footerData === null) {
      dispatch(actions.loadData(true));
    }
  }, [footerData, dispatch]);

  useEffect(() => {
    if (success !== null) {
      setMessage(success);
      setIsOpen(true);
      setType('success');
    }
    if (error !== null) {
      setMessage(error);
      setIsOpen(true);
      setType('error');
    }
  }, [success, error]);
  return <>
    {isFetching && <Preloader />}
    {!isFetching && <div id='globalContainer'>
      < Header setIsShow={setIsShowPopUp} />
      <Notification isOpen={isOpen} handleClose={closeNotify} message={message} type={type} />
      <div id='#anchor' />
      <div className='globalContainer'>
        <RouteSwitcher setIsShowPopUp={setIsShowPopUp} />
        {isShowPopUp &&
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <PopUp show={isShowPopUp} onclose={() => setIsShowPopUp(false)} />
        </div>
        }
      </div>
      {footerData && <FooterForm data={footerData} />}
    </div>}
  </>;
};

export default App;
