import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useLocation} from 'react-router-dom'
import HeaderPage from '../../Components/HeaderPage';
import style from './style.module.css';
import InformationBlock from '../../Components/InformationBlock';
import ImageBlock from '../../Components/ImageBlock';
import Preloader from '../../Components/Preloader';
import { RootState } from '../../../store/RootReducer';
import { actions } from '../slice';
import NextBlock from '../../Components/NextBlock';
import nextPage from '../../../img/kotana/nextPage.png'

const Kotana = () => {
  const {pathname} = useLocation()
  const {kotanaData, isFetching} = useSelector((state: RootState)=> state.pages)
  const dispatch = useDispatch()
  useEffect(()=>{
    if (kotanaData === null){
      dispatch(actions.loadData({fetch: true, endPoint: pathname}))
    }
  })
  return <>
    {isFetching && <Preloader />}
    {!isFetching && <>
      <HeaderPage
        description={kotanaData?.header.description?.value}
        title={kotanaData?.header.title.value} image={undefined}
        link={kotanaData?.header?.link?.value} background={style.headerBackground} imageStyle=''
        whiteIcon
      />
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={kotanaData?.task.title.value} img={kotanaData?.task?.logo?.value}
                          description={kotanaData?.task.description?.value} classname='' />
      </div>
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={kotanaData?.solution.title.value} img={kotanaData?.solution?.logo?.value}
                          description={kotanaData?.solution.description?.value} classname='' />
      </div>
      <ImageBlock img={kotanaData?.exampleBlock.logo?.value} title={kotanaData?.exampleBlock.title.value} />
      <div className={style.containerWhiteBlock}>
        <InformationBlock title={kotanaData?.organization.title.value} img={kotanaData?.organization?.logo?.value}
                          description={kotanaData?.organization.description?.value} classname={style.organization}
        descriptionClassName={style.descriptionContainer}/>
      </div>
      <div className={style.containerWhiteBlock}>
        <InformationBlock title={kotanaData?.addOrganization.title.value} img={kotanaData?.addOrganization?.logo?.value}
                          description={kotanaData?.addOrganization.description?.value} classname={style.organization}
        descriptionClassName={style.descriptionContainer}/>
      </div>
      <div className={style.containerGreyBlock}>
        <InformationBlock title={kotanaData?.project.title.value} img={kotanaData?.project?.logo?.value}
                          description={kotanaData?.project.description?.value} classname={style.organization}
        descriptionClassName={style.descriptionContainer}/>
      </div>
      <div className={style.containerGreyBlock}>
        <InformationBlock title={kotanaData?.addProject.title.value} img={kotanaData?.addProject?.logo?.value}
                          description={kotanaData?.addProject.description?.value} classname={style.organization}
        descriptionClassName={style.descriptionContainer}/>
      </div>
      <div className={style.containerWhiteBlock}>
        <InformationBlock title={kotanaData?.tasks.title.value} img={kotanaData?.tasks?.logo?.value}
                          description={kotanaData?.tasks.description?.value} classname={style.organization}
        descriptionClassName={style.descriptionContainer}/>
      </div>
      <div className={style.containerWhiteBlock}>
        <InformationBlock title={kotanaData?.addTask.title.value} img={kotanaData?.addTask?.logo?.value}
                          description={kotanaData?.addTask.description?.value} classname={style.organization}
        descriptionClassName={style.descriptionContainer}/>
      </div>
      <div className={style.containerGreyBlock}>
        <InformationBlock title={kotanaData?.calendar.title.value} img={kotanaData?.calendar?.logo?.value}
                          description={kotanaData?.calendar.description?.value} classname={style.organization}
        descriptionClassName={style.descriptionContainer}/>
      </div>
      <div className={style.containerGreyBlock}>
        <InformationBlock title={kotanaData?.notes.title.value} img={kotanaData?.notes?.logo?.value}
                          description={kotanaData?.notes.description?.value} classname={style.organization}
        descriptionClassName={style.descriptionContainer}/>
      </div>
      <div className={style.containerGreyBlock}>
        <InformationBlock title={kotanaData?.reports.title.value} img={kotanaData?.reports?.logo?.value}
                          description={kotanaData?.reports.description?.value} classname={style.organization}
        descriptionClassName={style.descriptionContainer}/>
      </div>
      <div className={style.containerWhiteBlock}>
        <InformationBlock title={kotanaData?.projectManagement.title.value}
                          img={kotanaData?.projectManagement?.logo?.value}
                          description={kotanaData?.projectManagement.description?.value} classname={style.organization}
        descriptionClassName={style.descriptionContainer}/>
      </div>
      <div className={style.containerWhiteBlock}>
        <InformationBlock title={kotanaData?.bills.title.value}
                          img={kotanaData?.bills?.logo?.value}
                          description={kotanaData?.bills.description?.value} classname={style.organization}
        descriptionClassName={style.descriptionContainer}/>
      </div>
      <div className={style.containerWhiteBlock}>
        <InformationBlock title={kotanaData?.seo.title.value}
                          img={kotanaData?.seo?.logo?.value}
                          description={kotanaData?.seo.description.value} classname={style.seoOrganization}
        descriptionClassName={style.descriptionContainer}/>
        <InformationBlock title={null}
                          img={null}
                          description={kotanaData?.seo.descriptionItems} classname={style.organization}
                          descriptionClassName={style.descriptionContainer}/>
      </div>
      <div className={style.containerGreyBlock}>
        <InformationBlock title={kotanaData?.dev.title.value}
                          img={kotanaData?.dev?.logo?.value}
                          description={kotanaData?.dev.description?.value} classname={style.organization}
                          descriptionClassName={style.descriptionContainer}/>
      </div>
      <div className={style.containerGreyBlock}>
        <InformationBlock title={kotanaData?.chat.title.value}
                          img={kotanaData?.chat?.logo?.value}
                          description={kotanaData?.chat.description?.value} classname={style.organization}
                          descriptionClassName={style.descriptionContainer}/>
      </div>
      <div className={style.containerGreyBlock}>
        <InformationBlock title={kotanaData?.mail.title.value}
                          img={kotanaData?.mail?.logo?.value}
                          description={kotanaData?.mail.description?.value} classname={style.organization}
                          descriptionClassName={style.descriptionContainer}/>
      </div>
      <div className={style.containerGreyBlock}>
        <InformationBlock title={kotanaData?.VoxImplant.title.value}
                          img={kotanaData?.VoxImplant?.logo?.value}
                          description={kotanaData?.VoxImplant.description?.value} classname={style.organization}
                          descriptionClassName={style.descriptionContainer}/>
      </div>
      <div className={style.containerWhiteBlock}>
        <InformationBlock title={kotanaData?.users.title.value}
                          img={kotanaData?.users?.logo?.value}
                          description={kotanaData?.users.description?.value} classname={style.organization}
                          descriptionClassName={style.descriptionContainer}/>
      </div>
      <div className={style.containerWhiteBlock}>
        <InformationBlock title={kotanaData?.personalArea.title.value}
                          img={kotanaData?.personalArea?.logo?.value}
                          description={kotanaData?.personalArea.description?.value} classname={style.organization}
                          descriptionClassName={style.descriptionContainer}/>
        <div style={{margin: '20px auto'}}/>
        <InformationBlock title={kotanaData?.personalAreaBlockTwo.title.value}
                          img={kotanaData?.personalAreaBlockTwo?.logo?.value}
                          description={kotanaData?.personalAreaBlockTwo.description} classname={style.organization}
                          descriptionClassName={style.descriptionContainer}/>
        <InformationBlock title={kotanaData?.personalAreaBlockThree.title.value}
                          img={kotanaData?.personalAreaBlockThree?.logo?.value}
                          description={kotanaData?.personalAreaBlockThree.description?.value}
                          classname={style.organization}
                          descriptionClassName={style.descriptionContainer}/>
      </div>
      <NextBlock logo={nextPage} link={kotanaData?.nextPage.link.value} id={2} imageStyle={style.nextBlock}/>
    </>}
  </>;
};

export default Kotana;
