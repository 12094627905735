import React from 'react';
import { IconButton, Toolbar } from '@material-ui/core';
import Form from '../Form';
import Subscription from '../Subscription';

const FooterForm = ({ data }) => (
  <section id='contacts' className='contact section'>
    <div className='container'>
      <div className='contact__inner'>
        <div className='contact__info'>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <p><a href={`tel:${data?.telephoneNumber.value || ''}`} className='contact__tel text'
            // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: data?.telephoneNumber.value || '' }} /></p>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <p><a href='mailto:info@it-meteor.ru' className='contact__mail text'
            // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: data?.mail.value || '' }} /></p>
          {data.address.map(item => <div className='contact__info-box' key={item.id}>
            <p className='contact__info-title'
              // eslint-disable-next-line react/no-danger
               dangerouslySetInnerHTML={{ __html: item?.title.value || '' }} />
            <p className='contact__info-text text'
              // eslint-disable-next-line react/no-danger
               dangerouslySetInnerHTML={{ __html: item?.description.value || '' }} />
          </div>)}
          <div className='contact__form'>
            <Subscription isPopUp={false} />
          </div>
          <div className='social'>
            {data?.linkvk?.value && <a href={data.linkvk.value} target='_blank' rel="noreferrer">
              <IconButton>
                <img width="24" height="24" src="/social/vk.svg" alt=""/>
              </IconButton>
            </a>}
            {data?.linktelegram?.value && <a href={data.linktelegram.value} target='_blank' rel="noreferrer">
              <IconButton>
                <img width="24" height="24" src="/social/telegram.png" alt=""/>
              </IconButton>
            </a>}
            {data?.linkwa?.value && <a href={data.linkwa.value} target='_blank' rel="noreferrer">
            <IconButton>
              <img width="24" height="24" src="/social/whatsapp.png" alt=""/>
            </IconButton>
            </a>}
          </div>
          <Toolbar/>
          <p>© 2010 - {new Date().getFullYear()} ООО «ФОКГрупп», ИНН: 5405423878</p>
        </div>
        <div className='contact__form'>
          <Form isPopUp={false} />
        </div>
      </div>
    </div>
  </section>
);

export default FooterForm;
