import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import HeaderPage from '../../Components/HeaderPage';
import style from './style.module.css';
import InformationBlock from '../../Components/InformationBlock';
import Report from '../../Components/Report';
import ImageBlock from '../../Components/ImageBlock';
import { RootState } from '../../../store/RootReducer';
import { actions } from '../slice';
import Preloader from '../../Components/Preloader';
import NextBlock from '../../Components/NextBlock';
import person12 from '../../../img/page/person12.jpg'

const B2Camp = () => {
  const { b2campData, isFetching } = useSelector((state: RootState) => state.pages);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  useEffect(() => {
    if (b2campData === null) {
      dispatch(actions.loadData({ fetch: true, endPoint: pathname }));
    }
  });
  return <>
    {isFetching && <Preloader />}
    {!isFetching && <>
      <HeaderPage
        description={b2campData?.header.description.value}
        title={b2campData?.header.title.value} image={undefined}
        link={b2campData?.header?.link?.value} background={style.headerBackground} imageStyle=''
        whiteIcon
      />
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={b2campData?.task.title.value} img={b2campData?.task?.logo?.value}
                          description={b2campData?.task.description.value} classname='' />
      </div>
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={b2campData?.solution.title.value} img={b2campData?.solution?.logo?.value}
                          description={b2campData?.solution.description.value} classname='' />
        <Report
          title={b2campData?.solution?.report?.title.value}
          img={person12}
          description={b2campData?.solution?.report?.description.value}
          name={b2campData?.solution?.report?.name.value}
        />
      </div>
      <ImageBlock img={b2campData?.exampleBlock.logo.value} title={null} />
      <div className={style.containerWhiteBlock}>
        <InformationBlock title={b2campData?.kidsBlock.title.value} img={b2campData?.kidsBlock?.logo?.value}
                          description={b2campData?.kidsBlock.description.value} classname='' />
      </div>
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={b2campData?.tasksBlock.title.value} img={b2campData?.tasksBlock?.logo?.value}
                          description={b2campData?.tasksBlock.description.value} classname='' />
      </div>
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={b2campData?.financeBlock.title.value} img={b2campData?.financeBlock?.logo?.value}
                          description={b2campData?.financeBlock.description.value} classname='' />
      </div>
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={b2campData?.dashboard.title.value} img={b2campData?.dashboard?.logo?.value}
                          description={b2campData?.dashboard.description.value} classname='' />
      </div>
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={b2campData?.reportsBlock.title.value} img={b2campData?.reportsBlock?.logo?.value}
                          description={b2campData?.reportsBlock.description.value} classname={style.image} />
      </div>
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={b2campData?.privateArea.title.value} img={b2campData?.privateArea?.logo?.value}
                          description={b2campData?.privateArea.description.value} classname='' />
      </div>
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={b2campData?.otherRole.title.value} img={b2campData?.otherRole?.logo?.value}
                          description={b2campData?.otherRole.description.value} classname='' />
      </div>
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={b2campData?.boxVersion.title.value} img={b2campData?.boxVersion?.logo?.value}
                          description={b2campData?.boxVersion.description.value} classname='' />
      </div>
      <div className={style.blockWithoutPadTop}>
      <NextBlock logo={b2campData?.nextPage.image.value} link={b2campData?.nextPage.link.value} id={2} />
      </div>
    </>}
  </>;
};

export default B2Camp;
