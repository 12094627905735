import React from 'react';


const TechStack = ({data}) => (<>
      <section className='tech section'>
        <div className='container'>
          <div className='tech__title'// eslint-disable-next-line react/no-danger
               dangerouslySetInnerHTML={{__html: data?.title?.value || ''}}/>
          <div className='tech__inner'>
            {data?.items?.map(element => <div className='tech__box' key={element.id}>
              <div className={element.id === 3 ? 'tech__box-title3' : 'tech__box-title'}
                  // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{ __html: element.title?.value }} />
              <ul className='tech__list'>
                {element.item.map(item => <li key={item.id}>
                  <div className='tech__list-box'>
                    <div className='tech__list-img' data-aos='zoom-in' data-aos-delay='100'><img
                      src={item.logo?.value} alt='' /></div>
                    <div className='tech__list-info'>
                      <div className='tech__list-title text' data-aos='zoom-in' data-aos-delay='100'
                          // eslint-disable-next-line react/no-danger
                           dangerouslySetInnerHTML={{ __html: item.title?.value }} />
                      <div className='tech__list-links' data-aos='zoom-in' data-aos-delay='100'>
                        {/* {item.projects.map(project => */}
                        {/*    <Link to={project.link?.value} key={project.id} */}
                        {/*          dangerouslySetInnerHTML={{ __html: project.title?.value }} />)} */}
                      </div>
                    </div>
                  </div>
                </li>)}
              </ul>
            </div>)}
          </div>
        </div>
      </section>
    </>
  );

export default TechStack;

