import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import style from './style.module.css';
import ImageBlock from '../../Components/ImageBlock';
import { RootState } from '../../../store/RootReducer';
import { actions } from '../slice';
import Preloader from '../../Components/Preloader';
import HeaderPage from '../../Components/HeaderPage';
import InformationBlock from '../../Components/InformationBlock';
import Report from '../../Components/Report';
import smartControlMain from '../../../img/page/smartControlMain.png'
import NextBlock from '../../Components/NextBlock';

const SmartControl = () => {
  const { smartControlData, isFetching } = useSelector((state: RootState) => state.pages);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (smartControlData === null) {
      dispatch(actions.loadData({ fetch: true, endPoint: pathname }));
    }
  }, [smartControlData]);
  return <>
    {isFetching && <Preloader />}
    {!isFetching && <>
      <HeaderPage
        description={smartControlData?.mainBlock?.description.value}
        title={smartControlData?.mainBlock?.title.value}
        image={smartControlMain}
        background={style.backgroundMain}
        link={smartControlData?.mainBlock?.link.value}
        imageStyle={null}
        whiteIcon
        imageStyleContainer={style.pageMainImage}
      />
      <div className={style.pageInfo}>
        <div className='container'>
          <div className={style.pageInfoContent}>
            <div>
              <div className={`${style.pageInfoTitle} ${style.text}`}
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{ __html: smartControlData?.infoBlock.infoAboutApp?.title.value || '' }} />
              <div className={style.pageInfoContent}>
                <div className={style.pageInfoText}
                  // eslint-disable-next-line react/no-danger
                     dangerouslySetInnerHTML={
                       { __html: smartControlData?.infoBlock.infoAboutApp?.description.value || '' }} />
              </div>
            </div>
            <div className={style.pageInfoDownloadLinks}>
              {smartControlData?.infoBlock.moreInfo?.logo.map(item =>
                <div key={item.id} className={style.informationBlock}>
                  <img src={item.logo.value} alt=''
                       className={style.pageInfoImg} />
                  <div className={style.moreInfoText}
                    // eslint-disable-next-line react/no-danger
                       dangerouslySetInnerHTML={
                         { __html: item.description.value || '' }} />
                </div>,
              )}
            </div>
          </div>
        </div>
      </div>
      <ImageBlock img={smartControlData?.exampleObjectBlock.logo.value} title={null} />
      <div className={style.containerWhiteBlock}>
        <InformationBlock
          title={smartControlData?.whatIsTechSupervisionBlock.title.value}
          img={smartControlData?.whatIsTechSupervisionBlock.logo.value}
          description={null}
          classname={style.whatIsTechSupervisionBlock}
          rightClassName={style.rightHeight}/>
        <Report
          title={smartControlData?.whatIsTechSupervisionBlock?.intro.title.value}
          img={smartControlData?.whatIsTechSupervisionBlock?.intro.logo.value}
          description={smartControlData?.whatIsTechSupervisionBlock?.intro.description.value}
          name={smartControlData?.whatIsTechSupervisionBlock?.intro.name.value}/>
        <InformationBlock
          title={null}
          img={null}
          description={smartControlData?.whatIsTechSupervisionBlock.description.value}
          classname={null}/>
        <Report
          title={smartControlData?.whatIsTechSupervisionBlock?.report.title.value}
          img={smartControlData?.whatIsTechSupervisionBlock?.report.logo.value}
          description={smartControlData?.whatIsTechSupervisionBlock?.report.description.value}
          name={smartControlData?.whatIsTechSupervisionBlock?.report.name.value}
          classname={style.whatIsTechSupervisionBlockReport}/>
      </div>
      <ImageBlock img={smartControlData?.exampleControlBlock.logo.value} title={null} />
      <div className={style.containerWhiteBlock}>
        <InformationBlock
          title={smartControlData?.techSupervisionBlock.title.value}
          description={null}
          img={smartControlData?.techSupervisionBlock.logo.value}
          classname={style.techSuperVisionImage}
          rightClassName={style.rightHeight}/>
        <Report
          title={smartControlData?.techSupervisionBlock?.report.title.value}
          img={smartControlData?.techSupervisionBlock?.report.logo.value}
          description={smartControlData?.techSupervisionBlock?.report.description.value}
          name={smartControlData?.techSupervisionBlock?.report.name.value}/>
      </div>
      <div className={style.examplePage3D}>
        <img alt='' src={smartControlData?.exampleMarkersBlock.logo.value} className={style.examplePage3DImage} />
      </div>
      <div className={style.techSupervisionBlock}>
        <div className='container'>
          <div className={style.pageInfoContent}>
            <div>
              <div className={`${style.pageInfoTitle} ${style.text}`}
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{ __html: smartControlData?.setPrescriptionBlock.title.value || '' }} />
              <div className={style.pageInfoContent}>
                <div className={style.pageInfoText}
                  // eslint-disable-next-line react/no-danger
                     dangerouslySetInnerHTML={
                       { __html: smartControlData?.setPrescriptionBlock.description.value || '' }} />
              </div>
            </div>
            <div className='project__inner'>
              <div className='project__list'>
                <div className='project__list-header'>
                  <ul className='project__info'>
                    {smartControlData?.setPrescriptionBlock.instruction.map(element => <li key={element.id}>
                      <div className='project__info-box'>
                        <div className={style.projectInfoImg}>
                          <img src={element.logo.value} alt=''
                               className='project__info-img-item' /></div>
                        <div className='project__info-info'>
                          <div className={`${style.projectInfoText}`}
                            // eslint-disable-next-line react/no-danger
                               dangerouslySetInnerHTML={
                                 { __html: element.description.value || '' }} />
                        </div>
                      </div>
                    </li>)}
                    <span className={style.stick}>
                                        <img src={smartControlData?.setPrescriptionBlock?.stick.value}
                                             alt='' style={{ width: '2px', height: '600px' }} /></span>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ImageBlock img={smartControlData?.exampleSetMarkersBlock.logo.value} title={null} />
      <div className={style.containerWhiteBlock}>
        <InformationBlock
          title={smartControlData?.analyticsBlock.title.value}
          description={smartControlData?.analyticsBlock.description.value}
          img={smartControlData?.analyticsBlock.logo.value}
          classname={style.analyticsBlockImage}/>
        <Report
          title={smartControlData?.analyticsBlock?.report.title.value}
          img={smartControlData?.analyticsBlock?.report.logo.value}
          description={smartControlData?.analyticsBlock?.report.description.value}
          name={smartControlData?.analyticsBlock?.report.name.value}/>
      </div>
      <ImageBlock img={smartControlData?.exampleAnalyticsBlock} title={null} />
      <div className={style.containerWhiteBlock}>
        <InformationBlock
          title={smartControlData?.pwaBlock.title.value }
          description={smartControlData?.pwaBlock.description.value}
          img={smartControlData?.pwaBlock.logo.value}
          classname={style.pwaImage}/>
      </div>
      <div className={style.examplePage3D}>
        <a href={smartControlData?.mainBlock?.link.value} target="_blank" rel="noreferrer">
          <img alt='' src={smartControlData?.exampleBlock.logo.value} className={style.image} />
        </a>
      </div>
      <div className={style.containerWhiteBlockWithOutBottom}>
        <div className={style.techSupervisionBlock}>
          <div className='container'>
            <div className={style.pageInfoContent}>
              <div>
                <div className={`${style.pageInfoTitle} ${style.text}`}
                  // eslint-disable-next-line react/no-danger
                     dangerouslySetInnerHTML={{ __html: smartControlData?.planFutureBlock.title.value || '' }} />
              </div>
            </div>
            <Report
              title={smartControlData?.planFutureBlock?.report.title.value}
              img={smartControlData?.planFutureBlock?.report.logo.value}
              description={smartControlData?.planFutureBlock?.report.description.value}
              name={smartControlData?.planFutureBlock?.report.name.value}/>
          </div>
        </div>
      </div>
      <NextBlock link={smartControlData?.nextPage.link.value} logo={smartControlData?.nextPage.image.value} id={2}/>
    </>}
  </>;
};

export default SmartControl;
