import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useLocation} from 'react-router-dom'
import Main from '../../Components/Main';
import Product from '../../Components/Product';
import Publication from '../../Components/Publication';
import Automation from '../../Components/Automation';
import Projects from '../../Components/Projects';
import TechStack from '../../Components/TechStack';
import { RootState } from '../../../store/RootReducer';
import { actions } from '../slice';
import Preloader from '../../Components/Preloader';


const HomePage = () => {
  const dispatch = useDispatch();
  const { homePageData, isFetching } = useSelector((state: RootState) => state.pages);
  const { pathname } = useLocation();
  useEffect(() => {
    if (homePageData === null) {
      dispatch(actions.loadData({ fetch: true, endPoint: pathname }));
    }
  }, [homePageData]);
  return <>
    {/* @ts-ignore */}
    {isFetching && <Preloader/>}
    {!isFetching && <>
      <Main data={homePageData?.mainData} />
      <Product data={homePageData?.productData} />
      <Automation data={homePageData?.automationData} />
      <Projects data={homePageData?.projectsData} />
      <TechStack data={homePageData?.techStack} />
      <Publication data={homePageData?.publicationData} />
    </>}
  </>;
};

export default HomePage;
