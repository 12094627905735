import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import App from './App/Index';
import reportWebVitals from './reportWebVitals';
import store from './store/store';


ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <Helmet title='Разработка, проектирование, развитие, технологичные решения | Метеор'>
          <meta name='description'
                content='Разработка CRM и ERP систем, сервисов,
                порталов, а также мобильных приложений с уникальным функционалом.' />
        </Helmet>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
