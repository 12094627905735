import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { IconButton, Tooltip } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import style from './style.module.css';

const HeaderPage: React.FC<any> = ({
                                     background,
                                     title,
                                     description,
                                     image,
                                     link,
                                     imageStyle,
                                     whiteIcon,
                                     imageStyleContainer,
                                   }) => <>
  <main className={`${style.pageMainSmart} ${background}`}>
    <div className='container'>
      <div className={style.contentContainer}>
        <div className='page-main__inner'>
          <div className='page-main__content'>
            <div className={`${style.pageMainTitle} ${style.text}`}
              // eslint-disable-next-line react/no-danger
                 dangerouslySetInnerHTML={{ __html: title || '' }} />
            <div className={style.pageMainDescr}
              // eslint-disable-next-line react/no-danger
                 dangerouslySetInnerHTML={{ __html: description || '' }} />
          </div>
        </div>
      </div>
    </div>
    <span className={imageStyleContainer}>
      <img src={image || ''} alt=''
           className={imageStyle !== null ? imageStyle : ''} /></span>
    {link !== null && <div className={style.linkContainer}>
      <Tooltip title='Перейти на сайт компании'>
        <a href={link} target='_blank' rel='noreferrer' className={style.link}>
          <IconButton aria-label='delete'>
            <OpenInNewIcon style={{ color: whiteIcon ? '#fff' : '#000' }} />
          </IconButton>
        </a>
      </Tooltip>
    </div>}
  </main>
</>;

export default HeaderPage;
