import {put, takeLatest} from 'redux-saga/effects';
import {PayloadAction} from "@reduxjs/toolkit";
import {actions} from './slice';
import {pagesAPI} from '../../Api/pagesApi/pagesAPI';

export function* loadData(action: PayloadAction<{ fetch: boolean, endPoint: string }>) {
    try {
        const {endPoint} = action.payload
        const data = yield pagesAPI.getData(endPoint === "/" ? '/home' : endPoint.toLowerCase())
        if (endPoint === '/project') {
            yield put(actions.setProjectData(data))
        } else if (endPoint === '/smartcontrol') {
            yield put(actions.setSmartControlData(data))
        } else if (endPoint === '/trebono') {
            yield put(actions.setTrebonoData(data))
        } else if (endPoint === '/omnicomm') {
            yield put(actions.setOmnicommData(data))
        }else if (endPoint === '/hculture') {
            yield put(actions.setHealthCultureData(data))
        }else if (endPoint === '/propostuplenie') {
            yield put(actions.setAdmissionNavigatorData(data))
        }else if (endPoint === '/b2camp') {
            yield put(actions.setB2campData(data))
        }else if (endPoint === '/kotana') {
            yield put(actions.setKotanaData(data))
        }else if (endPoint === '/broker') {
            yield put(actions.setBrokerData(data))
        }else if (endPoint === '/delo') {
            yield put(actions.setDeloData(data))
        }else {
            yield put(actions.setHomePageData(data))
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
}

export function* PagesSagaWatcher() {
    yield takeLatest(actions.loadData, loadData);
}
