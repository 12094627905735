import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import HeaderPage from '../../Components/HeaderPage';
import style from './style.module.css';
import InformationBlock from '../../Components/InformationBlock';
import ImageBlock from '../../Components/ImageBlock';
import Report from '../../Components/Report';
import { RootState } from '../../../store/RootReducer';
import { actions } from '../slice';
import Preloader from '../../Components/Preloader';
import mainImage from '../../../img/admissionNavigator/mainImage.png';
import NextBlock from '../../Components/NextBlock';


const AdmissionNavigator = () => {
  const { admissionNavigatorData, isFetching } = useSelector((state: RootState) => state.pages);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  useEffect(() => {
    if (admissionNavigatorData === null) {
      dispatch(actions.loadData({ fetch: true, endPoint: pathname }));
    }
  });
  return <div className={style.container}>
    {isFetching && <Preloader />}
    {!isFetching && <>
      <HeaderPage
        description={admissionNavigatorData?.mainBlock.description.value}
        link={admissionNavigatorData?.mainBlock?.link?.value}
        background={style.mainBackground}
        image={mainImage}
        title={admissionNavigatorData?.mainBlock.title.value}
        imageStyle={style.imageStyle}
        whiteIcon={false}
        imageStyleContainer={style.mainImage}
      />
      <div className='page-info_admission'>
        <div className='container'>
          <div className={`${style.pageInfoTitle} text`}
            // eslint-disable-next-line react/no-danger
               dangerouslySetInnerHTML={{
                 __html: admissionNavigatorData?.infoBlock?.infoAboutApp?.title.value || '',
               }} />
          <div className='page-info__inner'>
            <div className='page-info__block'>
              {admissionNavigatorData?.infoBlock?.infoBlockWithLogo?.map(item =>
                <div className='page-info__box' key={item.id}>
                  <div className='page-info__box-img'><img src={item.logo.value || ''} alt=''
                                                           className='page-info__box-img-item' /></div>
                  <div className={`${style.pageInfoDescription} ${style.text}`}
                    // eslint-disable-next-line react/no-danger
                       dangerouslySetInnerHTML={{ __html: item?.description.value || '' }} />
                </div>)}
            </div>
            <div className='page-info__content'>
              <div className={`${style.pageInfoText} ${style.text}`}
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={
                     { __html: admissionNavigatorData?.infoBlock?.infoAboutApp?.description.value || '' }} />
            </div>
          </div>
        </div>
      </div>
      <div className={style.containerGreyBlock}>
        <InformationBlock
          title={admissionNavigatorData?.historyBlock?.title?.value}
          description={admissionNavigatorData?.historyBlock.description.value}
          img={admissionNavigatorData?.historyBlock?.logo?.value}
          classname={style.interfaceApp} />
      </div>
      <div className={style.containerWhiteBlock}>
        <InformationBlock
          title={admissionNavigatorData?.choiceBlock.title.value}
          description={admissionNavigatorData?.choiceBlock.description.value}
          img={admissionNavigatorData?.choiceBlock?.logo?.value}
          classname={style.lensImage} />
      </div>
      <ImageBlock img={admissionNavigatorData?.universitiesBlock.logo.value} title={null} />
      <div className={style.containerWhiteBlock}>
        <InformationBlock
          title={admissionNavigatorData?.calculator.title.value}
          description={admissionNavigatorData?.calculator.description.value}
          img={admissionNavigatorData?.calculator?.logo?.value}
          classname={style.calculator} />
        <Report title={admissionNavigatorData?.calculator?.report?.title.value}
                img={admissionNavigatorData?.calculator?.report?.logo.value}
                name={admissionNavigatorData?.calculator?.report?.name.value}
                description={admissionNavigatorData?.calculator.report?.description.value} />
      </div>
      <ImageBlock img={admissionNavigatorData?.universitiesBlock1.logo.value} title={null} />
      <div className={style.expertBlock}>
        <InformationBlock
          title={admissionNavigatorData?.consultationBlock.title.value}
          description={admissionNavigatorData?.consultationBlock.description.value}
          img={admissionNavigatorData?.consultationBlock?.logo?.value}
          classname={style.expert} />
      </div>
      <ImageBlock img={admissionNavigatorData?.exampleBlock.logo.value} title={null} />
      <div className={style.containerWhiteBlock}>
        <InformationBlock
          title={admissionNavigatorData?.profTestBlock.title.value}
          description={admissionNavigatorData?.profTestBlock.description.value}
          img={admissionNavigatorData?.profTestBlock?.logo?.value}
          classname={style.profTest} />
      </div>
      <ImageBlock img={admissionNavigatorData?.profTestExampleBlock.logo.value} title={null} />
      <div className={style.containerWhiteBlock}>
        <InformationBlock
          title={admissionNavigatorData?.exhibitionsBlock.title.value}
          description={admissionNavigatorData?.exhibitionsBlock.description.value}
          img={admissionNavigatorData?.exhibitionsBlock?.logo?.value}
          classname={style.exhibitions} />
      </div>
      <ImageBlock img={admissionNavigatorData?.exhibitionExampleBlock.logo.value} title={null} />
      <div className={style.containerWhiteBlock}>
        <InformationBlock
          title={admissionNavigatorData?.webinarBlock.title.value}
          description={admissionNavigatorData?.webinarBlock.description.value}
          img={admissionNavigatorData?.webinarBlock?.logo?.value}
          classname={style.webinar} />
        <Report
          title={admissionNavigatorData?.webinarBlock?.report?.title.value}
          img={admissionNavigatorData?.webinarBlock?.report?.logo.value}
          name={admissionNavigatorData?.webinarBlock?.report?.name.value}
          description={admissionNavigatorData?.webinarBlock?.report?.description.value} />
      </div>
      <ImageBlock img={admissionNavigatorData?.webinarExampleBlock.logo.value} title={null} />
      <div className={style.containerWhiteBlock}>
        <InformationBlock
          title={admissionNavigatorData?.articlesBlock.title.value}
          description={admissionNavigatorData?.articlesBlock.description.value}
          img={admissionNavigatorData?.articlesBlock?.logo?.value}
          classname={style.article} />
      </div>
      <ImageBlock img={admissionNavigatorData?.articlesExampleBlock.logo.value} title={null} />
      <div className={style.containerWhiteBlock}>
        <InformationBlock
          title={admissionNavigatorData?.professionBlock.title.value}
          description={admissionNavigatorData?.professionBlock.description.value}
          img={admissionNavigatorData?.professionBlock?.logo?.value}
          classname={style.profession} />
      </div>
      <ImageBlock img={admissionNavigatorData?.professionExampleBlock.logo.value} title={null} />
      <div className={style.containerWhiteBlock}>
        <InformationBlock
          title={admissionNavigatorData?.controlBlock.title.value}
          description={admissionNavigatorData?.controlBlock.description.value}
          img={admissionNavigatorData?.controlBlock?.logo?.value}
          classname={style.control} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={admissionNavigatorData?.block3D.logo.value || ''}
             style={{ maxWidth: '1500px', width: '100%' }} alt='' />
      </div>
      <div className={style.containerWhiteBlock}>
        <InformationBlock
          title={admissionNavigatorData?.devBlock.title.value}
          description={admissionNavigatorData?.devBlock.description.value}
          img={admissionNavigatorData?.devBlock?.logo?.value}
          classname={style.devImage} />
        <Report
          title={admissionNavigatorData?.devBlock?.report?.title.value}
          img={admissionNavigatorData?.devBlock?.report?.logo.value}
          name={admissionNavigatorData?.devBlock?.report?.name.value}
          description={admissionNavigatorData?.devBlock?.report?.description.value}
        />
      </div>
      <InformationBlock
        title={admissionNavigatorData?.nextBlock.title.value}
        description={admissionNavigatorData?.nextBlock.description.value}
        img={admissionNavigatorData?.nextBlock?.logo?.value}
        classname={style.profession} />
      <NextBlock
        logo={admissionNavigatorData?.nextPage.image.value}
        link={admissionNavigatorData?.nextPage.link.value} id={2} />
    </>}
  </div>;
};

export default AdmissionNavigator;
;
