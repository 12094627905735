import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import style from './style.module.css';
import InformationBlock from '../../Components/InformationBlock';
import RatingComponents from '../../Components/RatingComponents';
import { RootState } from '../../../store/RootReducer';
import { actions } from '../slice';
import Preloader from '../../Components/Preloader';
import mainImage from '../../../img/omnicomm/mainImage.png';
import NextBlock from '../../Components/NextBlock';

const Omnicomm = ({ setIsShowPopUp }) => {
  const { omnicommData, isFetching } = useSelector((state: RootState) => state.pages);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  useEffect(() => {
    if (omnicommData === null) {
      dispatch(actions.loadData({ fetch: true, endPoint: pathname }));
    }
  });
  return <>
    {isFetching && <Preloader />}
    {!isFetching && <>
      <main className={style.pageMainSmart}>
        <div className='container'>
          <div className={style.contentContainer}>
            <div className={`page-main__inner ${style.item1}`}>
              <div className='page-main__content'>
                <div className={`${style.pageMainTitle} ${style.text}`}
                  // eslint-disable-next-line react/no-danger
                     dangerouslySetInnerHTML={{ __html: omnicommData?.mainBlock?.title.value || '' }} />
                <div className={style.pageMainDescr}
                  // eslint-disable-next-line react/no-danger
                     dangerouslySetInnerHTML={{ __html: omnicommData?.mainBlock?.description.value || '' }} />
              </div>
            </div>
            <div className={style.orange} />
          </div>
        </div>
        <span className={style.mainImage}><img src={mainImage || ''} alt=''
                                               className={style.mainImageContainer} /></span>
      </main>
      <div className={style.pageInfo}>
        <div className='container'>
          <div className={`page-info__content ${style.pageInfoContainer}`}>
            <div className='page-info__text text'
              // eslint-disable-next-line react/no-danger
                 dangerouslySetInnerHTML={
                   { __html: omnicommData?.downLoadBlock?.description.value || '' }} />
            <div className='page-info__download'>
              <div className={`${style.pageInfoDownloadTitle} text`}
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={
                     { __html: omnicommData?.downLoadBlock?.title.value || '' }
                   } />
              <div className='page-info__download-links'>
                {omnicommData?.downLoadBlock?.logo.map(item =>
                  <a href={item.link.value || ''} target='_blank' rel='noopener noreferrer'
                     className='page-info__download-item' key={item.id}>
                    <img src={item.logo.value || ''} alt=''
                         className='page-info__download-img' /></a>)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className={style.who}>
        <div className='container'>
          <div className={`${style.whoTitle} title text`}
            // eslint-disable-next-line react/no-danger
               dangerouslySetInnerHTML={{ __html: omnicommData?.whoIsWhoBox?.title.value || '' }} />
          <div className={`${style.underTitle}`}
            // eslint-disable-next-line react/no-danger
               dangerouslySetInnerHTML={{ __html: omnicommData?.whoIsWhoBox?.description.value || '' }} />
          <div className='who__inner'>
            {omnicommData?.whoIsWhoBox?.item?.map(item => <div className={style.whoBox} key={item.id}>
              <div className='who__box-img'><img src={item.logo.value || ''} alt=''
                                                 className='who__box-img-item' />
              </div>
              <div className='who__box-text'><span>{item.nameCompany.value}</span>{item.description.value}
              </div>
            </div>)}
          </div>
        </div>
      </section>
      <InformationBlock
        title={omnicommData?.moneyBlockInfo.title.value}
        description={omnicommData?.moneyBlockInfo.description.value}
        img={omnicommData?.moneyBlockInfo.logo.value} classname={style.imageMoney} />
      <div className={style.containerBlock}>
        <InformationBlock
          title={omnicommData?.blockOne.title.value}
          description={omnicommData?.blockOne.description.value}
          img={omnicommData?.blockOne.logo.value} classname={style.imageCheckList} />
        <InformationBlock
          title={omnicommData?.blockTwo.title.value}
          description={omnicommData?.blockTwo.description.value}
          img={omnicommData?.blockTwo.logo.value} classname={style.imageMoney} />
        <InformationBlock
          title={omnicommData?.blockThree.title.value}
          description={omnicommData?.blockThree.description.value}
          img={omnicommData?.blockThree.logo.value} classname={style.imageMoney} />
      </div>
      <div className={style.containerWhiteBlock}>
        <InformationBlock
          title={omnicommData?.blockFour.title.value}
          description={omnicommData?.blockFour.description.value}
          img={omnicommData?.blockFour.logo.value} underText={omnicommData?.blockFour?.underText?.value}
          classname={style.imageCalculator} />
      </div>
      <div className={style.containerWhiteBlockWithOutTopPad}>
        <InformationBlock
          title={omnicommData?.blockFive.title.value}
          description={omnicommData?.blockFive.description.value}
          img={omnicommData?.blockFive.logo.value} classname={style.imageCalculator} />
      </div>
      <InformationBlock
        title={omnicommData?.blockSix.title.value}
        description={omnicommData?.blockSix.description.value}
        img={omnicommData?.blockSix.logo.value} classname={style.imageMoney} />
      <div className={style.containerWhiteBlock}>
        <InformationBlock
          title={omnicommData?.blockSeven.title.value}
          description={omnicommData?.blockSeven.description}
          img={omnicommData?.blockSeven.logo.value} classname={style.imageInterface} />
      </div>
      <InformationBlock
        title={omnicommData?.blockEight.title.value}
        description={omnicommData?.blockEight.description.value}
        img={omnicommData?.blockEight.logo} classname={style.telephoneImage} />
      <div className={style.examplePage3D}>
        <img alt='' src={omnicommData?.imageBlock.value || ''} className={style.image} />
      </div>
      <div className={style.pageInfo}>
        <div className='container'>
          <div className={`page-info__content ${style.pageInfoContainer}`}>
            <div className='page-info__text text'
              // eslint-disable-next-line react/no-danger
                 dangerouslySetInnerHTML={
                   { __html: omnicommData?.rating.description.value || '' }} />
            <div className='page-info__download'>
              <RatingComponents minValue={omnicommData?.rating.minValue} maxValue={omnicommData?.rating.maxValue}
                                images={omnicommData?.rating.ratingImages} />
              <div className={`${style.pageInfoDownloadTitle} text`}
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={
                     { __html: omnicommData?.downLoadBlock?.title.value || '' }
                   } />
              <div className='page-info__download-links'>
                {omnicommData?.downLoadBlock?.logo.map(item =>
                  <a href={item.link.value || ''} target='_blank' rel='noopener noreferrer'
                     className='page-info__download-item' key={item.id}>
                    <img src={item.logo.value || ''} alt=''
                         className='page-info__download-img' /></a>)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.examplePage3D}>
        <img alt='' src={omnicommData?.image3dBlock.value || ''} className={style.image} />
      </div>
      <InformationBlock
        title={omnicommData?.whatNext.title.value}
        description={omnicommData?.whatNext.description.value}
        img={omnicommData?.whatNext.logo.value} classname={style.imageMoney} />
      <div className={style.containerWhiteBlock}>
        <InformationBlock
          title={omnicommData?.optimizationBlock.title.value}
          description={omnicommData?.optimizationBlock.description.value}
          img={omnicommData?.optimizationBlock.logo.value} classname={style.imageMoney} />
        <div className={`${style.buttonContainer} container`}>
          <button type='submit' onClick={() => setIsShowPopUp(true)} className={style.button}>Получить консультацию
          </button>
        </div>
      </div>
      <NextBlock logo={omnicommData?.nextPage.image.value} link={omnicommData?.nextPage.link.value} id={2}/>
    </>}
  </>;
};

export default Omnicomm;


