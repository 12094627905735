import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import style from '../../Pages/Omnicomm/style.module.css';

interface IProps {
  title: string | null | undefined
  underText?: any
  description: any
  img: any
  classname: any
  descriptionClassName?: any
  rightClassName?: any
}

const InformationBlock: React.FC<IProps> = ({
  title,
  description,
  img,
  classname,
  descriptionClassName,
  rightClassName,
  underText,
}) => {
  const [image, setImage] = useState(Array.isArray(img) ? img[0].value : null);
  const [isShow, setShow] = useState(false);
  const element = document.getElementById('target');


  function isFullyShown() {
    const wt = $(window)?.scrollTop();
    const wh = $(window)?.height();
    const eh = $('#target')?.height();
    const et = $('#target')?.offset()?.top;
    setShow(et >= wt && et + eh <= wh + wt);
  }

  useEffect(() => {
    if (img !== null && Array.isArray(img)) {
      window.addEventListener('scroll', isFullyShown);
    }
    return () => {
      window.removeEventListener('scroll', isFullyShown);
    };
  }, [img]);


  const scroll = (event) => {
    if (element !== null) {
      if (isShow && event.deltaY > 0 && img !== null) {
        setTimeout(() => {
          document.ontouchmove = () => {
            event.preventDefault();
          };
          setImage(img[1].value);
          $('body').removeClass('stop-scrolling');
          document.ontouchmove = () => true;
        }, 1000);
      } else if (isShow && event.deltaY < 0 && img !== null) {
        setTimeout(() => {
          document.ontouchmove = () => {
            event.preventDefault();
          };
          setImage(img[0].value);
          document.removeEventListener('wheel', scroll);
          $('body').removeClass('stop-scrolling');
          document.ontouchmove = () => true;

        }, 1000);
      }
    }
  };
  useEffect(() => {
    if (img !== null && Array.isArray(img)) {
      if (isShow) {
        $('body').addClass('stop-scrolling');
        document.addEventListener('wheel', scroll);
      }
      if (!isShow) {
        document.removeEventListener('wheel', scroll);
      }
    }
    return () => {
      document.removeEventListener('wheel', scroll);
      $('body').removeClass('stop-scrolling');
      document.ontouchmove = () => true;
    };
  }, [isShow, img]);

  useEffect(() => {
    if (img !== null && Array.isArray(img)) {
      if (isShow) {
        $('body').addClass('stop-scrolling');
        document.ontouchmove = (e) => {
          e.preventDefault();
        };
        setTimeout(() => {
          if (image === img[0].value) {
            setImage(img[1].value);
          } else {
            setImage(img[0].value);
          }
          $('body').removeClass('stop-scrolling');
          document.ontouchmove = () => true;
        }, 1000);
      }
    }
    return () => {
      $('body').removeClass('stop-scrolling');
    };
  }, [isShow]);

  return <>
    <div className='container'>
      <div className={`${style.pageInfoContent} ${style.text}`}>
        <div>
          {title && <div className={`${style.pageInfoTitle} ${style.title}`}
            // eslint-disable-next-line react/no-danger
                         dangerouslySetInnerHTML={{ __html: title || '' }} />}
          {underText?.length && <div className={`${style.pageInfoUnderText} ${style.title}`}
            // eslint-disable-next-line react/no-danger
                                     dangerouslySetInnerHTML={{ __html: underText || '' }} />}
          {Array.isArray(description) ? (<ul className={style.flexColumn}>{description.map(item =>
              <li key={item.id}
                  className={`${descriptionClassName === undefined
                    ? style.pageInfoText
                    : descriptionClassName} ${style.text}`}
                // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={
                    { __html: item.value || '' }} />)}
            </ul>
          ) : (<div className={style.pageInfoContent}>
            <div className={descriptionClassName === undefined ? style.pageInfoText : descriptionClassName}
              // eslint-disable-next-line react/no-danger
                 dangerouslySetInnerHTML={
                   { __html: description || '' }} />
          </div>)}
        </div>
        <div className={rightClassName || ''}>
          {Array.isArray(img) ? <img id='target' src={image} alt='' className={classname} />
            : img !== null && <img src={img} alt=''
                                   className={classname} />}
        </div>
      </div>
    </div>
  </>;
};

export default InformationBlock;
