import React, { useState } from 'react';
import './style.css';
import { Link as LinkScroll } from "react-scroll";
import { Link } from 'react-router-dom';
import logo from '../../../img/logo.svg';

const Header = ({ setIsShow}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <header className='header'>
        <div className='header__inner'>
          <div className='header__logo'>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <Link to='/' className='header__logo-link'><img src={logo} alt=''
                                                                    className='header__logo-img' /></Link>
            <p className='header__logo-info'>ПИШЕМ КОД С 2010 ГОДА</p>
          </div>
          <div className='header__nav'>
            <ul className='header__links'>
              <li><Link to='project'>Проекты</Link></li>
              {/* <li><Link to='services'>Услуги</Link></li> */}
              {/* <li><Link to='testimonials'>Отзывы</Link></li> */}
               <li><LinkScroll smooth="true" duration={500} to='contacts'>Контакты</LinkScroll></li>
            </ul>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div className='header__btn' onClick={() => setIsShow(true)}>
              {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
              <span className='header__btn-link contact__btn open-form'>Написать
                нам</span>
            </div>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div className={!isOpen ? 'header__burger' : 'header__burger active'} onClick={() => setIsOpen(!isOpen)}>
              <span />
            </div>
            <nav className={!isOpen ? 'header__menu' : 'header__menu active'}>
              <ul className='header__list'>
                <li><Link to='project' onClick={() => setIsOpen(!isOpen)}>Проекты</Link></li>
                {/* <li><Link to='testimonials' onClick={() => setIsOpen(!isOpen)}>Отзывы</Link></li> */}
                 <li><LinkScroll smooth="true" duration={500} to='contacts' onClick={() => setIsOpen(!isOpen)}>
                   Контакты</LinkScroll></li>
                {/* eslint-disable-next-line max-len */}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div className='burger__btn' onClick={() => setIsShow(true)}>
                  <span className='header__btn-link contact__btn open-form'>Написать
                  нам</span></div>
                <div className='burger__contact'>
                  <a href='tel:+79537787236' className='burger__contact-link burger__contact-tel'>+7 953 778 72 36</a>
                  <a href='mailto:info@it-meteor.ru'
                     className='burger__contact-link burger__contact-mail'>info@it-meteor.ru</a>
                </div>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
