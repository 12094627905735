import React from 'react';
import Form from '../Form';
// import Subscription from '../Subscription';

const PopUp = ({ show, onclose }) =>
  <>
    <div className={show ? 'popup popup-form show' : 'popup popup-form'}>
      <div className='popup__content'>
        <Form isPopUp />
        {/* <Subscription isPopUp /> */}
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className='close' onClick={onclose} />
    </div>

    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
    <div className={show ? 'bg-menu show' : 'bg-menu'} onClick={onclose} />
    <div className='bg-menu2' />
  </>
;

export default PopUp;
