import { put, takeLatest } from 'redux-saga/effects';
import {PayloadAction} from "@reduxjs/toolkit";
import {mainAPI} from "../Api/mainAPI";
import { actions } from './slice';

export function* loadData() {
    try {
        const data = yield mainAPI.getFooterData()
        yield put(actions.loadedData({footerData: data}))
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
}

export function* loadPrivacy() {
    try {
        const data = yield mainAPI.getPrivacy()
        yield put(actions.loadedPrivacy( data))
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
}

export function* sendForm(action: PayloadAction<any>) {
    try {
        const {telephone, name, description, email} = action.payload
        const data = yield mainAPI.sendForm(telephone, name, description, email)
        if (data.status === 200){
            yield put(actions.sendFormSuccess(data.data.message))
        }
    } catch (e) {
        yield put(actions.setError(e.response.data.message[0]))
    }
}
export function* sendFormSubscription(action: PayloadAction<any>) {
    try {
        const {name, email} = action.payload
        const data = yield mainAPI.sendFormSubscription(name, email)
        if (data.status === 200){
            yield put(actions.sendFormSuccess(data.data.message))
        }
    } catch (e) {
        yield put(actions.setError(e.response.data.message))
    }
}

export function* mainSagaWatcher() {
    yield takeLatest(actions.loadData, loadData);
    yield takeLatest(actions.loadPrivacy, loadPrivacy);
    yield takeLatest(actions.sendForm, sendForm);
    yield takeLatest(actions.sendFormSubscription, sendFormSubscription);
}
