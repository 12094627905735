import React, { useEffect } from 'react';
import {
  Route,
  useLocation,
} from 'react-router-dom';
import HomePage from '../../Pages/HomePage';
import Error404 from '../Error404';
import ProjectPage from '../../Pages/ProjectPage';
import Trebono from '../../Pages/Trebono';
import SmartControl from "../../Pages/SmartControl";
import Omnicomm from "../../Pages/Omnicomm";
import HealthCulture from '../../Pages/HealthCulture';
import AdmissionNavigator from '../../Pages/AdmissionNavigator';
import B2Camp from '../../Pages/B2Camp';
import InDev from '../InDev';
import Kotana from '../../Pages/Kotana';
import BrokerPage from '../../Pages/BrokerPage';
import DeloPage from '../../Pages/DeloPage';

const RouteSwitcher = ({setIsShowPopUp}) => {
  const { pathname } = useLocation();
  const HOME = '/';
  const PROJECTS = '/project';
  const TREBONO = '/trebono';
  const SMARTCONTROL = '/smartcontrol';
  const OMNICOMM = '/omnicomm';
  const HCULTURE = '/hculture'
  const B2CAMP = '/b2camp'
  const KOTANA = '/kotana'
  const MTRK = '/mtrk-mir'
  const MAXIMUM = '/maximum'
  const PROPOSTUPLENIE = '/propostuplenie'
  const BROKER = '/broker'
  const DELO = '/delo'
  useEffect(() => {
    if (pathname !== '/') {
      window.scrollTo({
        top: -2000,
        behavior: 'smooth',
      });
    }
  }, [pathname]);

  switch (pathname) {
    case HOME:
      return <Route exact path={HOME} render={() => <HomePage />} />;
    case PROJECTS:
      return <Route exact path={PROJECTS} render={() => <ProjectPage />} />;
    case TREBONO:
      return <Route exact path={TREBONO} render={() => <Trebono />} />;
      case SMARTCONTROL:
      return <Route exact path={SMARTCONTROL} render={() => <SmartControl />} />;
      case OMNICOMM:
      return <Route exact path={OMNICOMM} render={() => <Omnicomm setIsShowPopUp={setIsShowPopUp} />} />;
      case HCULTURE:
      return <Route exact path={HCULTURE} render={() => <HealthCulture />} />;
      case B2CAMP:
      return <Route exact path={B2CAMP} render={() => <B2Camp />} />;
      case BROKER:
      return <Route exact path={BROKER} render={() => <BrokerPage />} />;
      case DELO:
      return <Route exact path={DELO} render={() => <DeloPage />} />;
      case KOTANA:
      return <Route exact path={KOTANA} render={() => <Kotana />} />;
      case MAXIMUM:
      return <Route exact path={MAXIMUM} render={() => <InDev />} />;
      case MTRK:
      return <Route exact path={MTRK} render={() => <InDev />} />;
      case PROPOSTUPLENIE:
      return <Route exact path={PROPOSTUPLENIE} render={() => <AdmissionNavigator />} />;
    default:
      return <Route exact path='*' render={() => <Error404 />} />;
  }
};
export default RouteSwitcher;
