import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CircularProgress } from '@material-ui/core';

const Preloader = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <div style={{
    width: '100%',
    height: '90vh',
    zIndex: 999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}>
    <CircularProgress style={{ zIndex: 999, color: '#FFBD13' }} />
  </div>
}

export default Preloader
