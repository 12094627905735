import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import style from './style.module.css';
import Preloader from '../../Components/Preloader';
import InformationBlock from '../../Components/InformationBlock';
import Report from '../../Components/Report';
import { RootState } from '../../../store/RootReducer';
import HeaderPage from '../../Components/HeaderPage';
import { actions } from '../slice';
import mainLogo from '../../../img/hculture/mainLogo.png'
import NextBlock from '../../Components/NextBlock';
import nextImage from '../../../img/hculture/nextPage.png'

const HealthCulture = () => {
  const { healthCultureData, isFetching } = useSelector((state: RootState) => state.pages);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  useEffect(() => {
    if (healthCultureData === null) {
      dispatch(actions.loadData({ fetch: true, endPoint: pathname }));
    }
  });

  return <>
    {isFetching && <Preloader />}
    {!isFetching && <>
      <HeaderPage
        description={healthCultureData?.mainBlock?.description.value}
        title={healthCultureData?.mainBlock?.title.value}
        image={mainLogo}
        background={style.backgroundMain}
        link={null}
        imageStyle={null}
        whiteIcon
        imageStyleContainer={style.mainImage}
      />
      <div className={style.pageInfo}>
        <div className='container'>
          <div className={`page-info__content ${style.pageInfoContainer}`}>
            <div className={`${style.infoTitle} ${style.pageInfoTitle} text`}
              // eslint-disable-next-line react/no-danger
                 dangerouslySetInnerHTML={
                   { __html: healthCultureData?.downloadInfo?.title.value || '' }
                 } />
            <div className='page-info__text'
              // eslint-disable-next-line react/no-danger
                 dangerouslySetInnerHTML={
                   { __html: healthCultureData?.downloadInfo.description.value || '' }} />
            <div className='page-info__download'>
              <div className={`${style.pageInfoDownloadTitle} text`}
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={
                     { __html: healthCultureData?.downloadInfo?.downloadTitle.value || '' }
                   } />
              <div className='page-info__download-links'>
                {healthCultureData?.downloadInfo?.logo.map(item =>
                  <a href={item.link.value || ''} target='_blank' rel='noopener noreferrer'
                     className='page-info__download-item' key={item.id}>
                    <img src={item.logo.value || ''} alt=''
                         className='page-info__download-img' /></a>)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.containerWhiteBlock}>
        <InformationBlock title={healthCultureData?.infoBlock.title.value}
                          description={healthCultureData?.infoBlock.description}
                          img={healthCultureData?.infoBlock.logo.value}
                          classname={style.imageInterface}
                          descriptionClassName={style.flexColumnItem}
        />
      </div>
      <div className={style.examplePage}>
        <div className='container'>
          <div className={style.Block3D}>
            <div className={`${style.pageInfoText} ${style.whiteText}`}
              // eslint-disable-next-line react/no-danger
                 dangerouslySetInnerHTML={
                   { __html: healthCultureData?.Block3D.description.value || '' }} />
            <img alt='' src={healthCultureData?.Block3D.logo.value || ''} className={style.image} />
          </div>
          <div className={style.block3dReport}>
            <Report title={healthCultureData?.Block3D.report?.title.value}
                    img={healthCultureData?.Block3D.report.logo.value}
                    name={healthCultureData?.Block3D.report.name.value}
                    description={healthCultureData?.Block3D.report?.description.value} />
          </div>
        </div>
      </div>
      <div className={style.choice}>
        <InformationBlock title={healthCultureData?.choiceBlock.title.value}
                          description={healthCultureData?.choiceBlock.description.value}
                          img={healthCultureData?.choiceBlock.logo.value}
                          classname={style.choiceImage} />
      </div>
      <div className={style.containerGreyBlock}>
        <InformationBlock title={healthCultureData?.nurseCallBlock.title?.value}
                          description={healthCultureData?.nurseCallBlock.description.value}
                          img={healthCultureData?.nurseCallBlock.logo.value}
                          classname={style.healthBag} />
        <Report title={healthCultureData?.nurseCallBlock.report.title.value}
                img={healthCultureData?.nurseCallBlock.report.logo.value}
                name={healthCultureData?.nurseCallBlock.report.name.value}
                description={healthCultureData?.nurseCallBlock.report?.description.value} />
      </div>
      <div className={style.containerWhiteBlock}>
        <InformationBlock title={healthCultureData?.medicineArchiveBlock.title.value}
                          description={healthCultureData?.medicineArchiveBlock.description.value}
                          img={healthCultureData?.medicineArchiveBlock.logo.value}
                          classname={style.medicineArchive} />
      </div>
      <div className={style.containerGreyBlock}>
        <InformationBlock title={healthCultureData?.monitoring.title.value}
                          description={healthCultureData?.monitoring.description.value}
                          img={healthCultureData?.monitoring.logo.value}
                          classname={style.healthBag} />
      </div>
      <div className={style.containerWhiteBlock}>
        <InformationBlock title={healthCultureData?.riskAssessmentBlock.title.value}
                          description={healthCultureData?.riskAssessmentBlock.description.value}
                          img={healthCultureData?.riskAssessmentBlock.logo.value}
                          classname={style.riskAssessmentImage} />
      </div>
      <div className={style.containerGreyBlock}>
        <InformationBlock title={healthCultureData?.recommendBlock.title.value}
                          description={healthCultureData?.recommendBlock.description.value}
                          img={healthCultureData?.recommendBlock.logo.value}
                          classname={style.recommendImage} />
      </div>
      <div className={style.containerWhiteBlock}>
        <InformationBlock title={healthCultureData?.useCaseBlock.title?.value}
                          description={healthCultureData?.useCaseBlock.description.value}
                          img={healthCultureData?.useCaseBlock.logo.value}
                          classname={style.useCaseImage} />
        <Report title={healthCultureData?.useCaseBlock.report.title.value}
                img={healthCultureData?.useCaseBlock.report.logo.value}
                name={healthCultureData?.useCaseBlock.report.name.value}
                description={healthCultureData?.useCaseBlock.report?.description.value} classname={style.ReportThree} />
      </div>
      <div className={style.containerGreyBlock}>
        <InformationBlock title={healthCultureData?.regularRetroPerspectiveBlock.title.value}
                          description={healthCultureData?.regularRetroPerspectiveBlock.description.value}
                          img={healthCultureData?.regularRetroPerspectiveBlock.logo.value}
                          classname={style.healthBubble} />
      </div>
      <div className={style.containerWhiteBlock}>
        <InformationBlock title={healthCultureData?.corporateSpiritBlock.title.value}
                          description={healthCultureData?.corporateSpiritBlock.description.value}
                          img={healthCultureData?.corporateSpiritBlock.logo.value}
                          classname={style.animoji} />
      </div>
      <div className={style.containerGreyBlock}>
        <InformationBlock title={healthCultureData?.whatNextBlock.title.value}
                          description={healthCultureData?.whatNextBlock.description.value}
                          img={healthCultureData?.whatNextBlock.logo.value}
                          classname={style.animoji} />
      </div>
      <div className={style.containerWhiteBlockWithOutBottom}>
      <NextBlock logo={nextImage} link={healthCultureData?.nextPage.link.value} id={2} imageStyle={style.nextBlock} />
      </div>
    </>}
  </>;
};

export default HealthCulture;


