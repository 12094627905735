import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { DialogContentText } from '@material-ui/core';
import './style.css';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(-1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  onClose: () => void;
}

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const VideoPopUp = ({ open, onClose, link }) => <div>
    <Dialog maxWidth='sm' fullWidth onClose={onClose} aria-labelledby='customized-dialog-title' open={open}>
      <DialogContent dividers>
        <DialogContentText style={{boxSizing: 'border-box', display: 'flex', justifyContent: 'center'}}>
          <iframe width="600" height="365" src={`${link}?autoplay=1`} title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  </div>;

export default VideoPopUp;
