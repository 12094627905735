import React, { useEffect } from 'react';
import {useLocation} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Preloader from '../../Components/Preloader';
import HeaderPage from '../../Components/HeaderPage';
import style from './style.module.css';
import InformationBlock from '../../Components/InformationBlock';
import ImageBlock from '../../Components/ImageBlock';
import { RootState } from '../../../store/RootReducer';
import { actions } from '../slice';
import NextBlock from '../../Components/NextBlock';

const BrokerPage = () => {
  const { brokerData, isFetching } = useSelector((state: RootState) => state.pages);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  useEffect(() => {
    if (brokerData === null) {
      dispatch(actions.loadData({ fetch: true, endPoint: pathname }));
    }
  },[dispatch]);
  return <>
    {isFetching && <Preloader />}
    {!isFetching && <>
      <HeaderPage
        description={brokerData?.header.description?.value}
        title={brokerData?.header.title.value} image={undefined}
        link={brokerData?.header?.link?.value} background={style.headerBackground} imageStyle=''
        whiteIcon
      />
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={brokerData?.task.title.value} img={brokerData?.task?.logo?.value}
                          description={brokerData?.task.description?.value} classname='' />
      </div>
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={brokerData?.solution.title.value} img={brokerData?.solution?.logo?.value}
                          description={brokerData?.solution.description?.value} classname='' />
      </div>
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={brokerData?.AboutDesign.title.value} img={brokerData?.AboutDesign?.logo?.value}
                          description={brokerData?.AboutDesign.description?.value} classname='' />
      </div>
      <ImageBlock img={brokerData?.exampleBlock.logo?.value} title={brokerData?.exampleBlock.title.value} />
      <div className={style.containerWhiteBlock}>
        <InformationBlock title={brokerData?.structure.title.value} img={brokerData?.structure?.logo?.value}
                          description={brokerData?.structure.description?.value} classname={style.image} />
      </div>
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={brokerData?.people.title.value} img={brokerData?.people?.logo?.value}
                          description={brokerData?.people.description?.value} classname={style.image} />
      </div>
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={brokerData?.bid.title.value} img={brokerData?.bid?.logo?.value}
                          description={brokerData?.bid.description?.value} classname={style.image} />
      </div>
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={brokerData?.clients.title.value} img={brokerData?.clients?.logo?.value}
                          description={brokerData?.clients.description?.value} classname={style.image} />
      </div>
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={brokerData?.form.title.value} img={brokerData?.form?.logo?.value}
                          description={brokerData?.form.description?.value} classname={style.image} />
      </div>
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={brokerData?.documents.title.value} img={brokerData?.documents?.logo?.value}
                          description={brokerData?.documents.description?.value} classname={style.image} />
      </div>
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={brokerData?.info.title.value} img={brokerData?.info?.logo?.value}
                          description={brokerData?.info.description?.value} classname={style.image} />
      </div>
      <NextBlock logo={brokerData?.nextPage.image.value} link={brokerData?.nextPage.link.value} id={2}/>
    </>}
  </>
}

export default BrokerPage
