import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../slice';
import { RootState } from '../../../store/RootReducer';
import PopUpPrivacyPolicy from '../PopUpPrivacyPolicy';
import ErrorInputBlock from '../ErrorInputBlock';

const Form = ({ isPopUp }) => {
  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
  const [isOpen, setOpen] = useState(false);
  const [isFill, setFill] = useState(false);
  const dispatch = useDispatch();
  const { success, isSend } = useSelector((state: RootState) => state.main);

  const onSubmit = data => {
    dispatch(actions.sendForm(data));
  };
  useEffect(() => {
    if (success !== null) {
      reset(register);
    }
  }, [success]);
  useEffect(() => {
    const data = watch();
    if (data?.telephone?.length !== 0 || data?.email?.length !== 0) {
      setFill(true);
    }
    if (data?.telephone?.length === 0 && data?.email?.length === 0) {
      setFill(false);
    }
  }, [watch()]);


  // eslint-disable-next-line consistent-return
  return <>
    <div className={isPopUp ? 'contact__form contact__form-popup' : 'contact__form contact__form-title'}>
      <div className='contact__form-title'>Напишите нам</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='form__item'>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor='input-name'>Ваше имя*</label>
          <input type='text' className='form__input' id='input-name' placeholder='Ваше имя' required
                 {...register('name')} />
        </div>
        <div className='form__item'>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor='input-tel'>Номер телефона</label>
          <div style={{ display: 'flex' }}>
            <input type='tel' className={isPopUp ? 'form__input input-tel' : 'form__input'} id='input-tel'
                   placeholder='+7 (___) ___-__-__' {...register('telephone', { required: !isFill })} />
            {errors?.telephone?.type === 'required'
            && !isFill && <ErrorInputBlock message='одно из полей должно быть заполнено' />}
          </div>
        </div>
        <div className='form__item'>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor='input-mail'>E-mail</label>
          <div style={{ display: 'flex' }}>
            <input type='email' className='form__input' id='input-mail'
                   placeholder='Ваш E-mail'{...register('email', { required: !isFill })} />
            {errors?.email?.type === 'required' && !isFill &&
            <ErrorInputBlock message='одно из полей должно быть заполнено' />}
          </div>
        </div>
        <div className='form__item'>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor='input-text'>Опишите задачу</label>
          <textarea className='form__textarea' id='input-text'
                    placeholder='Хотим автоматизировать отдел производства'
                    {...register('description')} />
        </div>
        {/* eslint-disable-next-line react/button-has-type */}
        <button disabled={isSend} className='contact__btn'>Отправить</button>
      </form>
      <p className='contact__form-text text'>Нажимая кнопку «Отправить» Вы даёте своё согласие на обработку
        персональных данных и согласие с
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <span style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
                    {' '}политикой конфиденциальности.
                </span></p>
    </div>
    <PopUpPrivacyPolicy open={isOpen} onClose={() => setOpen(false)} />
  </>;
};


export default Form;
