import React  from 'react';
import style from './style.module.css'
import img from '../../../img/44.gif'

const InDev = () => <div className={style.container}>
    <div className={style.code}>Страница{' '}<span style={{ color: '#FFBD13' }}>в{' '}</span>Разработке</div>
    <img src={img} alt=''/>
  </div>

export default InDev
