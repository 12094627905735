import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Tooltip } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Preloader from '../../Components/Preloader';
import style from './style.module.css';
import InformationBlock from '../../Components/InformationBlock';
import { RootState } from '../../../store/RootReducer';
import { actions } from '../slice';
import NextBlock from '../../Components/NextBlock';

const DeloPage = () => {
  const { deloData, isFetching } = useSelector((state: RootState) => state.pages);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  useEffect(() => {
    if (deloData === null) {
      dispatch(actions.loadData({ fetch: true, endPoint: pathname }));
    }
  }, [dispatch]);
  return <>
    {isFetching && <Preloader />}
    {!isFetching && <>
      <main className={style.pageMainSmart}>
        <div className='container'>
          <div className={style.contentContainer}>
            <div className={`page-main__inner ${style.item1}`}>
              <div className='page-main__content'>
                <div className={`${style.pageMainTitle} ${style.text}`}
                  // eslint-disable-next-line react/no-danger
                     dangerouslySetInnerHTML={{ __html: deloData?.header?.title.value || '' }} />
                <div className={style.pageMainDescr}
                  // eslint-disable-next-line react/no-danger
                     dangerouslySetInnerHTML={{ __html: deloData?.header?.description.value || '' }} />
              </div>
            </div>
            <div className={style.orange} />
          </div>
        </div>
        <span className={style.mainImage}><img src={deloData?.header?.logo?.value || ''} alt=''
                                               className={style.mainImageContainer} /></span>
        <div className={style.linkContainer}>
          <Tooltip title='Перейти на сайт компании'>
            <a href={deloData?.header?.link?.value || ''} target='_blank' rel='noreferrer' className={style.link}>
              <IconButton aria-label='delete'>
                <OpenInNewIcon style={{ color: '#fff' }} />
              </IconButton>
            </a>
          </Tooltip>
        </div>
      </main>
      <div className={style.blockWithoutPadTop}>
        <div className="container">
          <div className={style.taskContainer}>
            <div className={style.title}
              // eslint-disable-next-line react/no-danger
                 dangerouslySetInnerHTML={{ __html: deloData?.task?.title.value || '' }} />
            <div className={style.description}
              // eslint-disable-next-line react/no-danger
                 dangerouslySetInnerHTML={{ __html: deloData?.task?.description.value || '' }} />
          </div>
        </div>
      </div>
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={deloData?.solution.title.value} img={deloData?.solution?.logo?.value || ''}
                          description={deloData?.solution.description?.value} classname={style.image1} />
      </div>
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={deloData?.internalPortal.title.value} img={deloData?.internalPortal?.logo?.value}
                          description={deloData?.internalPortal.description?.value} classname={style.image} />
      </div>
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={deloData?.details.title.value} img={deloData?.details?.logo?.value}
                          description={deloData?.details.description?.value} classname={style.image} />
      </div>
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={deloData?.order.title.value} img={deloData?.order?.logo?.value}
                          description={deloData?.order.description?.value} classname={style.image} />
      </div>
      <div className={style.blockWithoutPadTop}>
        <InformationBlock title={deloData?.integration.title.value} img={deloData?.integration?.logo?.value}
                          description={deloData?.integration.description?.value} classname={style.image2} />
      </div>
      <div className={style.blockWithoutPadTop}>
        <div className='container'>
        <div className={style.textContainer}>
          <div className={style.itemText}
            // eslint-disable-next-line react/no-danger
               dangerouslySetInnerHTML={{ __html: deloData?.resultBlock?.title.value || '' }} />
        </div>
        </div>
      </div>
      <NextBlock logo={deloData?.nextPage.image.value} link={deloData?.nextPage.link.value} id={2} />
    </>}
  </>;
};

export default DeloPage;
