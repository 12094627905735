import React, { useEffect } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { DialogContentText } from '@material-ui/core';
import './style.css'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/RootReducer';
import { actions } from '../../slice';
import Preloader from '../Preloader';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const PopUpPrivacyPolicy = ({open, onClose}) => {
  const {privacy, isFetching} = useSelector((state:RootState)=>state.main)
  const dispatch = useDispatch()
  useEffect(()=>{
    if (privacy === null){
      dispatch(actions.loadPrivacy(true))
    }
  },[dispatch])

  return (
    <>
      {isFetching && <Preloader/>}
      {!isFetching && <>
      <Dialog maxWidth='lg' fullWidth onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          Политика конфиденциальности
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText className='popUpContainer'>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={
            { __html: privacy?.value || '' }} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>}
    </>
  );
}

export default PopUpPrivacyPolicy
