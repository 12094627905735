import React from 'react';

const Main = ({data}) => <main className='main'>
    <div className='container'>
      <div className='main__inner'>
        <div className='main__content'>
          <div className='main__suptitle'
              // eslint-disable-next-line react/no-danger
               dangerouslySetInnerHTML={{ __html: data?.title.value }} />
          <div className='main__title' data-aos='fade-up' data-aos-delay='200'
              // eslint-disable-next-line react/no-danger
               dangerouslySetInnerHTML={{ __html: data?.description.value }} />
          <div className='main__descr text' data-aos='fade-up' data-aos-delay='500'
              // eslint-disable-next-line react/no-danger
               dangerouslySetInnerHTML={{ __html: data?.underText.value }} />
        </div>
      </div>
    </div>
  </main>;

export default Main;
