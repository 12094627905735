import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/RootReducer';
import Preloader from '../../Components/Preloader';
import { actions } from '../slice';

const ProjectPage = () => {
  const { projectData, isFetching } = useSelector((state: RootState) => state.pages);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (projectData === null) {
      dispatch(actions.loadData({ fetch: true, endPoint: pathname }));
    }
  }, [projectData]);
  return <>
    {isFetching && <Preloader />}
    {!isFetching && <section className='projects-content section'>
      <div className='container'>
        <div className='projects-content__title' data-aos='fade-up'>{projectData?.title}</div>
        <div className='projects-content__inner'>
          {/* eslint-disable-next-line react/no-array-index-key */}
          {projectData?.items.map((dataItem, index) => <div className='projects-content__block' key={index}>
            {dataItem?.map(item =>
              <Link to={item.link} className={`projects-content__box${item.id}`}
                    key={item.id}>
                <div data-aos='zoom-in' data-aos-delay={item.id % 2 !== 0 ? '200' : '300'}>
                  <div className={`projects-content__box-img projects-content__box-img${item.id}`}><img
                    src={item.logo} alt=''
                    className={`projects-content__box-img-item  projects-content__box-img-item${item.id}`} /></div>
                  <div className='projects-content__box-title'>{item.title}</div>
                  <div className='projects-content__box-descr text'>{item.description}</div>
                </div>
              </Link>,
            )}
          </div>)}
        </div>
      </div>
    </section>
    }
  </>;
};

export default ProjectPage;
