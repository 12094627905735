import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Avatar, Theme, makeStyles } from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createStyles } from '@material-ui/styles';
import style from '../../Pages/AdmissionNavigator/style.module.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  }),
);

type PropsType = {
  title: any
  img: any
  name: any
  description: any
  classname?: any
}


const Report : React.FC<PropsType> = ({title, img, name, description, classname}) => {
  const classes = useStyles();
 return <div className={`${style.report} container ${classname !== undefined ? classname : ''}`}>
  <div className='testimonials saving__testimonials1'>
    <div className='testimonials-inner'>
      <div className='testimonials-box'>
        <div className='testimonials-text'
          // eslint-disable-next-line react/no-danger
             dangerouslySetInnerHTML={{ __html: title || '' }} />
      </div>
      <div className='testimonials-person'>
        <Avatar alt="" src={img || ''} className={classes.large} />
        <div className='testimonials-person-info'>
          <div className='testimonials-person-name'
            // eslint-disable-next-line react/no-danger
               dangerouslySetInnerHTML={{ __html: name || '' }} />
          <div className='testimonials-person-work'
            // eslint-disable-next-line react/no-danger
               dangerouslySetInnerHTML={{ __html: description || '' }} />
        </div>
      </div>
    </div>
  </div>
 </div>
}
export default Report
