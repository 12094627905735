import React from "react";
import style from "../../Pages/SmartControl/style.module.css";

const ImageBlock = ({img, title}) => <div className={Array.isArray(img) ? style.examplePage2 : style.examplePage}>
    <div className='container'>
        {title !== null && <div className={`${style.pageInfoTitle} ${style.title} ${style.exampleTitle}`}>{title}</div>}
        {Array.isArray(img) ? <div className={style.exampleBlockFlexContainer}>
                {img.map(item => <img key={item.id} alt='' src={item.value} className={style.imageFlex}/>)}
            </div>
            : <img alt='' src={img} className={title === null ? style.image : style.imageWithTitle}/>
        }
    </div>
</div>

export default ImageBlock
