import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {IState} from "./types";
import {trebonoDataType} from "./Trebono/types";
import {smartControlDataType} from "./SmartControl/types";
import { homePageData } from './HomePage/types';
import { projectData } from './ProjectPage/types';
import { omnicommDataType } from './Omnicomm/types';
import { healthCultureDataType } from './HealthCulture/types';
import { admissionNavigatorDataType } from './AdmissionNavigator/types';
import { b2campDataType } from './B2Camp/types';
import { kotanaDataType } from './Kotana/types';
import { deloDataType } from './DeloPage/types';
import { brokerDataType } from './BrokerPage/types';

export const initialState: IState = {
  homePageData: null,
  projectData: null,
  smartControlData: null,
  trebonoData: null,
  omnicommData: null,
  healthCultureData: null,
  admissionNavigatorData: null,
  b2campData: null,
  kotanaData: null,
  deloData: null,
  brokerData: null,
  isFetching: false
};

const pagesSlice = createSlice({
  name: 'pagesSlice',
  initialState,
  reducers: {
    loadData(state, action: PayloadAction<{fetch:boolean, endPoint: string}>) {
      state.isFetching = action.payload.fetch;
    },
    setProjectData(state, action: PayloadAction<projectData>) {
      state.projectData = action.payload
      state.isFetching = false
    },
    setTrebonoData(state, action: PayloadAction<trebonoDataType>) {
      state.trebonoData = action.payload
      state.isFetching = false
    },
    setHomePageData(state, action: PayloadAction<homePageData>) {
      state.homePageData = action.payload
      state.isFetching = false
    },
    setSmartControlData(state, action: PayloadAction<smartControlDataType>) {
      state.smartControlData = action.payload
      state.isFetching = false
    },
    setOmnicommData(state, action: PayloadAction<omnicommDataType>) {
      state.omnicommData = action.payload
      state.isFetching = false
    },
    setHealthCultureData(state, action: PayloadAction<healthCultureDataType>) {
      state.healthCultureData = action.payload
      state.isFetching = false
    },
    setAdmissionNavigatorData(state, action: PayloadAction<admissionNavigatorDataType>) {
      state.admissionNavigatorData = action.payload
      state.isFetching = false
    },
    setB2campData(state, action: PayloadAction<b2campDataType>) {
      state.b2campData = action.payload
      state.isFetching = false
    },
    setKotanaData(state, action: PayloadAction<kotanaDataType>) {
      state.kotanaData = action.payload
      state.isFetching = false
    },
    setDeloData(state, action: PayloadAction<deloDataType>) {
      state.deloData = action.payload
      state.isFetching = false
    },
    setBrokerData(state, action: PayloadAction<brokerDataType>) {
      state.brokerData = action.payload
      state.isFetching = false
    },
    sendFormSubscription(state, action: PayloadAction<any>){
      state.isFetching = action.payload
    },
    sendForm(state, action: PayloadAction<any>){
      state.isFetching = action.payload
    },
    sendFormSuccess(state, action: PayloadAction<any>){
      state.isFetching = action.payload
    },
  },
});

export const { actions, reducer, name: sliceKey } = pagesSlice;
