import React, { useEffect, useState } from 'react';
import style from './style.module.css';

const RatingComponents = ({ minValue, maxValue, images }) => {
  const [value, setValue] = useState<any>(null)
  const [image, setImage] = useState<any>(null)
  const [isShow, setShow] = useState(false)
  const element = document.querySelector('#rating');

  const Visible = () => {
    if (element !== null) {
      // Все позиции элемента
      const targetPosition = {
        top: window.pageYOffset + element.getBoundingClientRect().top,
        left: window.pageXOffset + element.getBoundingClientRect().left,
        right: window.pageXOffset + element.getBoundingClientRect().right,
        bottom: window.pageYOffset + element.getBoundingClientRect().bottom,
      };
      // Получаем позиции окна
      const windowPosition = {
        top: window.pageYOffset,
        left: window.pageXOffset,
        right: window.pageXOffset + document.documentElement.clientWidth,
        bottom: window.pageYOffset + document.documentElement.clientHeight,
      };

      // eslint-disable-next-line max-len
      if (targetPosition.bottom > windowPosition.top && // Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
        // eslint-disable-next-line max-len
        targetPosition.top < windowPosition.bottom && // Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
        // eslint-disable-next-line max-len
        targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
        // eslint-disable-next-line max-len
        targetPosition.left < windowPosition.right) { // Если позиция левой стороны элемента меньше позиции правой чайти окна, то элемент виден справа
        // Если элемент полностью видно, то запускаем следующий код
        setShow(true)
      } else {
        // Если элемент не видно, то запускаем этот код
        setShow(false)
      }
    }
  };
// Запускаем функцию при прокрутке страницы
  window.addEventListener('scroll',Visible);

  useEffect(()=>{
    if (minValue){
      setValue(minValue.value)
    }
  },[minValue])
  useEffect(()=>{
    if (images){
      setImage(images[0].value)
    }
  },[images])
  useEffect(()=>{
    if (isShow && +value < +maxValue.value){
      setTimeout(()=>{
        setValue(prevState => (+prevState + 0.1).toFixed(1))
      },100)
    }
    // eslint-disable-next-line eqeqeq
    if (value == 2.5){
      setImage(images[1].value)
    }    // eslint-disable-next-line eqeqeq
    if (value == 3){
      setImage(images[2].value)
    }    // eslint-disable-next-line eqeqeq
    if (value == 3.5){
      setImage(images[3].value)
    }    // eslint-disable-next-line eqeqeq
    if (value == 4){
      setImage(images[4].value)
    }    // eslint-disable-next-line eqeqeq
    if (value == 4.5){
      setImage(images[5].value)
    }

    if (!isShow && images){
      setImage(images[0].value)
      setValue(minValue.value)
    }

  },[value, isShow, setValue])
  return <div className={style.ratingContainer} id='rating'>
    {value !== null && <div className={style.ratingValue}>{value}</div>}
    {image !== null && <img alt='' src={image} className={style.ratingImage}/>}
  </div>
}

export default RatingComponents
