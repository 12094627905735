import React from 'react';
import {Link} from 'react-router-dom';


const Publication = ({data}) => (
  <section className='automation section'>
    <div className='container'>
      <div className='automation__header'>
        <div className='automation__header-info'>
          <div className='automation__header-title' data-aos='fade-up'
            // eslint-disable-next-line react/no-danger
               dangerouslySetInnerHTML={{__html: data?.title.value || ''}}/>
          <div className='automation__header-text text' data-aos='fade-up' data-aos-delay='30'
            // eslint-disable-next-line react/no-danger
               dangerouslySetInnerHTML={{__html: data?.description.value || ''}}/>
        </div>
      </div>
      <div className='automation__inner'>
        {data?.items.map(item => <div key={item.id} className='automation__box'>
          <a href={item.link.value}>
            <div className='automation__box-img'><img src={item.logo.value}
                                                      alt='' className='automation__box-img-item' /></div>
            <div className='automation__info'>
              <div className='automation__box-title'
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{ __html: item.title.value }}/>
              <div className='automation__box-text text'
                // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{ __html: item.description.value }}/>
            </div>
            <div className='automation__header-btn mobile-none'>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            </div>
          </a>
        </div>)}
      </div>
      <div className='automation__header-btn desctop-none'>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link to='/project' className='products__btn-link'>Все проекты</Link>
      </div>
    </div>
  </section>
)

export default Publication;
