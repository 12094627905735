// eslint-disable-next-line import/no-extraneous-dependencies
import axios from "axios";


export const instance = axios.create({
    baseURL: "https://back.2021.it-meteor.ru/api/",
    withCredentials: false

});

export const pagesAPI = {
    getData(endPoint) {
        return instance.get(`${endPoint}`).then(response => response.data);
    },
};
