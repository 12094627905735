import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import { IconButton, Tooltip } from '@material-ui/core';
import style from './style.module.css';

const ErrorInputBlock = ({ message }) => <Tooltip title={message}>
  <IconButton>
    <WarningIcon
      className={style.icon} />
  </IconButton>
</Tooltip>

export default ErrorInputBlock;
