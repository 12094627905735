import React, { useEffect, useState } from 'react';
import $ from 'jquery';

const TimeLine = ({ title, items, imageActive, image, dots }) => {
  const [show, setShow] = useState(false)
  const element = document.getElementById('horizontal-scroller');


  const scroll = (event) => {
    const scrollElement = $('#horizontal-scroller')
    const scrollElementSw = $(scrollElement)[0]?.scrollWidth
    const scrollElementW = scrollElement?.width();
    if (element !== null) {
      // eslint-disable-next-line max-len
      if ((show && event.deltaY > 0 && !(scrollElement.scrollLeft() >= scrollElementSw-scrollElementW)) ||
        (show && event.deltaY < 0 && element.scrollLeft > 0)) {
        let modifier;
        // иные режимы возможны в Firefox
        if (event.deltaMode === event.DOM_DELTA_PIXEL) {
          modifier = 1;
        } else if (event.deltaMode === event.DOM_DELTA_LINE) {
          modifier = parseInt(getComputedStyle(element).lineHeight, 10);
        } else if (event.deltaMode === event.DOM_DELTA_PAGE) {
          // eslint-disable-next-line react/no-this-in-sfc
          modifier = element.clientHeight;
        }
        if (event.deltaY !== 0) {
          // замена вертикальной прокрутки горизонтальной
          // eslint-disable-next-line react/no-this-in-sfc
          element.scrollLeft += modifier * event.deltaY;
        }
      }else{
        $('body').removeClass('stop-scrolling');
        document.removeEventListener('wheel', scroll);
      }
    }
  }

  function isFullyShown() {
    const wt = $(window)?.scrollTop();
    const wh = $(window)?.height();
    const eh = $('#horizontal-scroller')?.height();
    const et = $('#horizontal-scroller')?.offset()?.top;
    setShow(et >= wt && et + eh <= wh + wt)
  }

  useEffect(()=>{
    if (!("ontouchstart" in window)) {
      document.addEventListener('scroll', isFullyShown)
    }
    return ()=>{
      document.removeEventListener('scroll',isFullyShown)
    }
  },[])
  useEffect(() => {
    if (!("ontouchstart" in window)) {
      if (show) {
        $('body').addClass('stop-scrolling');
        document.addEventListener('wheel', scroll);
      }
      if (!show) {
        document.removeEventListener('wheel', scroll);
      }
    }
    return ()=>{
      $('body').removeClass('stop-scrolling');
      document.removeEventListener('wheel', scroll);
    }
  }, [show]);

  return <div id='con'>
    <div className='timeline'>
      <div className='container'>
        <div className='timeline__title title text'>{title}</div>
      </div>
      <div className='timeline__list' id='horizontal-scroller'>
        {items?.map(item => <div key={item.id} className={item.id === 1
          ? 'timeline__box1'
          : 'timeline__box'}>
          <div className='timelist__img'>
            <img src={item.id === 1 ? imageActive : image} alt=''
                 className='timeline__img-item' />
          </div>
          <div className='timeline__box-title'>{item.title.value}</div>
          <div className='timeline__box-text'>
            {item.description.value}
          </div>
        </div>)}

        <span className='dots2'>
                        <img src={dots} alt='' className='timeline__img-item' />
                    </span>
      </div>
    </div>
  </div>;
};

export default TimeLine;
