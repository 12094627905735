import {instance} from "./pagesApi/pagesAPI";

export const mainAPI = {
    getFooterData() {
        return instance.get(`footerData`).then(response => response.data);
    },
    getPrivacy() {
        return instance.get(`politycs`).then(response => response.data);
    },
    sendForm(telephone, name, description, email) {
        return instance.post(`send/email`, {
            telephone, name, description, email
        })
    },
    sendFormSubscription(name, email) {
        return instance.post(`subscribe`, {
            name, email
        })
    },
};
