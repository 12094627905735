import {combineReducers} from "@reduxjs/toolkit"
import {reducer as pagesSlice} from '../App/Pages/slice';
import {reducer as mainReducer} from '../App/slice';


const RootReducer = combineReducers({
  main: mainReducer,
  pages: pagesSlice
})

export type RootState = ReturnType<typeof RootReducer>

export default RootReducer
