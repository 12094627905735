import {all} from "redux-saga/effects"
import {mainSagaWatcher} from "../App/saga";
import {PagesSagaWatcher} from "../App/Pages/saga";





export function* RootWatcher(){
    yield all([mainSagaWatcher(), PagesSagaWatcher()])
}
