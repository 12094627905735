import React, {useEffect} from "react";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import {Snackbar} from "@material-ui/core";

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const Notification = ({isOpen,message, handleClose, type}) => {
    useEffect(()=>{
        if (isOpen){
            setTimeout(()=>{
                handleClose()
            },3000)
        }
    },[isOpen])
    return (
        <Snackbar
            open={isOpen}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleClose} severity={type}>
               <span>{message}</span>
            </Alert>
        </Snackbar>
    )
}

export default Notification
