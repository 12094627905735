import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../slice';
import { RootState } from '../../../store/RootReducer';
import PopUpPrivacyPolicy from '../PopUpPrivacyPolicy';
import ErrorInputBlock from '../ErrorInputBlock';

const Subscription = ({ isPopUp }) => {
  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
  const [isOpen, setOpen] = useState(false);
  const [isFill, setFill] = useState(false);
  const dispatch = useDispatch();
  const { success, isSend } = useSelector((state: RootState) => state.main);

  const onSubmit = data => {
    dispatch(actions.sendFormSubscription(data));
  };
  useEffect(() => {
    if (success !== null) {
      reset(register);
    }
  }, [success]);
  useEffect(() => {
    const data = watch();
    if (data?.email?.length !== 0) {
      setFill(true);
    }
    if (data?.email?.length === 0) {
      setFill(false);
    }
  }, [watch()]);


  // eslint-disable-next-line consistent-return
  return <>
    <div className={isPopUp ? 'contact__form contact__form-popup' : 'contact__form contact__form-title'}>
      <div className='contact__form-title'>Подписаться на новости</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='form__item'>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor='input-name'>Ваше имя*</label>
          <input type='text' className='form__input' id='input-name' placeholder='Ваше имя' required
                 {...register('name')} />
        </div>

        <div className='form__item'>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor='input-mail'>E-mail</label>
          <div style={{ display: 'flex' }}>
            <input type='email' className='form__input' id='input-mail'
                   placeholder='Ваш E-mail'{...register('email', { required: !isFill })} />
            {errors?.email?.type === 'required' && !isFill &&
            <ErrorInputBlock message='Поле Email должно быть заполнено, иначе мы не сможем отправлять Вам новости' />}
          </div>
        </div>

        {/* eslint-disable-next-line react/button-has-type */}
        <button disabled={isSend} className='subscribe__btn'>Подписаться</button>
      </form>
    </div>
    <PopUpPrivacyPolicy open={isOpen} onClose={() => setOpen(false)} />
  </>;
};


export default Subscription;
