import React from 'react';
import dots from '../../../img/project/dots.svg';

const Projects = ({data}) => <>
    <section className='project section'>
      <div className='container'>
        <div className='project__inner'>
          <div className='project__list'>
            <div className='project__list-header'>
              <div className='project__list-title'
                  // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{__html: data?.createText.title.value || ''}}/>
              <div className='project__list-descr text'
                  // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{__html: data?.createText.description.value || ''}}/>
                <ul className='project__info'>
                  {data?.create.map(element => <li key={element.id}>
                    <div className='project__info-box'>
                      <div className='project__info-img'><img src={element.logo.value} alt=''
                                                              className='project__info-img-item' /></div>
                      <div className='project__info-info'>
                        <div className='project__info-title'
                            // eslint-disable-next-line react/no-danger
                             dangerouslySetInnerHTML={{__html: element.title.value || ''}}/>
                        <div className='project__info-text text'
                            // eslint-disable-next-line react/no-danger
                             dangerouslySetInnerHTML={{__html: element.description.value || ''}}/>
                      </div>
                    </div>
                  </li>)}
                  <span className='dots'><img src={dots} alt='' className='imgDots' /></span>
                </ul>
            </div>
          </div>
          <div className='project__list'>
            <div className='project__list-header'>
              <div className='project__list-title'
                  // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{__html: data?.developmentText.title.value || ''}}/>
              <div className='project__list-descr text'
                  // eslint-disable-next-line react/no-danger
                   dangerouslySetInnerHTML={{__html: data?.developmentText.description.value || ''}}/>
            </div>
            <ul className='project__info'>
              {data?.development.map(element => <li key={element.id}>
                <div className='project__info-box'>
                  <div className='project__info-img'><img src={element.logo.value} alt=''
                                                          className='project__info-img-item' /></div>
                  <div className='project__info-info'>
                    <div className='project__info-title'
                        // eslint-disable-next-line react/no-danger
                         dangerouslySetInnerHTML={{__html: element.title.value || ''}}/>
                    <div className='project__info-text text'
                        // eslint-disable-next-line react/no-danger
                         dangerouslySetInnerHTML={{__html: element.description.value || ''}}/>
                  </div>
                </div>
              </li>)}
              <span className='dots'><img src={dots} alt='' className='imgDots2' /></span>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </>;

export default Projects;

