import React from 'react';
import { Link } from 'react-router-dom';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import style from './style.module.css';

type propsType = {
  link: any
  logo: any
  id:number
  imageStyle?:any
}

const NextBlock:React.FC<propsType> = ({link, logo, id, imageStyle}) =>
  <div className={style.container}>
    <div className={style.linkContainer}>
      <Link to={link} className={style.link}>Следующий проект</Link>
      <ArrowRightAltIcon className={style.link}/>
    </div>
    <Link to={link} className={style.projectsContentBox}>
      <div data-aos='zoom-in'>
        <div className={`${style.imageContainer} projects-content__box-img projects-content__box-img2`}><img
          src={logo} alt=''
          className={`projects-content__box-img-item
           projects-content__box-img-item${id} ${imageStyle!==undefined? imageStyle: ''}`} /></div>
      </div>
    </Link>
  </div>;

export default NextBlock;
