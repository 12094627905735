import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actionLoadedDataType, IState, privacyType } from './types';

export const initialState: IState = {
    success: null,
    error: null,
    footerData: null,
    privacy: null,
    isSend: false,
    isFetching: false
};

const mainSlice = createSlice({
    name: 'mainSlice',
    initialState,
    reducers: {
        loadData(state, action: PayloadAction<any>) {
            state.isFetching = action.payload;
        },
        loadedData(state, action: PayloadAction<actionLoadedDataType>) {
            const {
                footerData
            } = action.payload
            state.footerData = footerData
            state.isFetching = false
        },
        loadPrivacy(state, action: PayloadAction<any>) {
            state.isFetching = action.payload;
        },
        loadedPrivacy(state, action: PayloadAction<privacyType>) {
            state.privacy = action.payload
            state.isFetching = false
        },
        // eslint-disable-next-line no-unused-vars
        sendForm(state, action: PayloadAction<any>) {
            state.isSend = true
        },
        // eslint-disable-next-line no-unused-vars
        sendFormSubscription(state, action: PayloadAction<any>) {
            state.isSend = true
        },

        sendFormSuccess(state,action: PayloadAction<string|null>) {
            state.success = action.payload
            state.isSend = false
        },
        setError(state,action: PayloadAction<string|null>) {
            state.error = action.payload
        },

    },
});

export const { actions, reducer, name: sliceKey } = mainSlice;
